import { FETCH_PROFILE, FETCH_EDIT_PROFILE, FETCH_SIMILAR_PROFILES, FETCH_CONNECTIONS, FETCH_CONVERSATION, FETCH_USER_LIST, START_LOADING, END_LOADING, START_LOADING_PROFILES, END_LOADING_PROFILES } from '../constants/actionTypes';
import * as api from '../api'; //* as api means everything as api

// get a single user profile
export const getUserProfile = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.fetchProfile(id);

        dispatch({ type: FETCH_PROFILE, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const editProfile = (id, updatedProfile) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading

        const { data } = await api.editProfile(id, updatedProfile);

        dispatch({ type: FETCH_EDIT_PROFILE, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const getSimilarProfiles = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_PROFILES }); // here starts the loading
        const { data } = await api.getSimilarProfiles(id);

        dispatch({ type: FETCH_SIMILAR_PROFILES, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING_PROFILES }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const getConnectionRequests = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_PROFILES }); // here starts the loading
        const { data } = await api.getConnectionRequests(id);

        dispatch({ type: FETCH_SIMILAR_PROFILES, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING_PROFILES }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const getAISearchProfiles = (id, request) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_PROFILES }); // here starts the loading
        const { data } = await api.getAISearchProfiles(id, request);

        dispatch({ type: FETCH_SIMILAR_PROFILES, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING_PROFILES }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const connectProfiles = (id, profileId, action) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading

        const { data } = await api.connectProfiles(id, profileId, action);

        if (data.isNewMatch) {
            alert("It's a match!"); // Display alert or handle match notification
        }

        dispatch({ type: FETCH_EDIT_PROFILE, payload: data.userProfile }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const deleteConnection = (id, profileId, history) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading

        const { data } = await api.deleteConnection(id, profileId);

        dispatch({ type: FETCH_EDIT_PROFILE, payload: data.userProfile }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
        history.push('/profile/chat');
    } catch (error) {
        console.log(error);
    }  
}

// get a single user profile
export const getConnections = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.getConnections(id);

        dispatch({ type: FETCH_CONNECTIONS, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

// get a single user profile
export const getConversation = (profileId1, profileId2) => async (dispatch) => {
    try {
        //dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.getConversation(profileId1, profileId2);

        dispatch({ type: FETCH_CONVERSATION, payload: data }); //send data to reducer
        //dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

export const sendChatMessage = (newMessage) => async (dispatch) => {
    try {
        //dispatch({ type: START_LOADING_CHAT });
        const { data } = await api.sendChatMessage(newMessage);

        //dispatch ({ type: COMMENT, payload: data });
        dispatch({ type: FETCH_CONVERSATION, payload: data });
        //dispatch({ type: END_LOADING_CHAT });
        return data.conversation;
    } catch (error) {
        console.log(error);
    }
}
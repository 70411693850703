import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #36d2f5 30%, #40f1b0 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const Privacy = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"
    sx={{ marginTop: '10px' }}>
        
        <Grid container display="flex" flexDirection="column" justifyContent="center" padding='0 5% 0 5%'>
            {/*<Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'32px', fontWeight: 'bold'} : { fontSize:'48px', fontWeight: 'bold' }} >Building the future of research.</Typography>*/}
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                Privacy Policy
            </Typography>

            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Last updated: February 1, 2024
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Welcome to our Privacy Policy. Thank you for taking the time to read it.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Our Commitment To You
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            At Cohuma, your privacy is a top priority. Your privacy is at the core of the way we design and build the services and products you know and love, so that you can fully trust them and focus on building meaningful connections.
            We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security, engineering, product design and others to make sure that our decisions are taken with the utmost respect for your privacy.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we’re doing our best to write our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            1. Who We Are
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We are Cohuma, an online service to make social connections and participate in the community, based in Berlin, Germany.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            2. Where This Privacy Policy Applies
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            This Privacy Policy applies to websites, apps, events and other services we operate under the Cohuma brand. For simplicity, we refer to all of these as our “services” in this Privacy Policy.
            Some services may require their own unique privacy policy. If a service has its own privacy policy, then that policy -- not this Privacy Policy -- applies.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            3. Information We Collect
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            It goes without saying, we can’t help you develop meaningful connections without some information about you, such as basic profile details and the types of people you’d like to meet. We also collect information about your use of our services such as access logs, as well as information from third parties, like when you access our services through your social media account or when you upload information from your social media account to complete your profile. If you want additional info, we go into more detail below.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You choose to give us certain information when using our services. This includes:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	When you create an account, you provide us with at least your email address.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	When you complete your profile, you share additional information with us necessary for the service to work, such as your name, date of birth, your interests and who you’d like to connect with. Where you provide such elements to us, you consent to us using it for the purposes identified and as laid out in this Privacy Policy. To add certain content, like pictures or videos, you may allow us to access your camera or photo album.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	When you subscribe to a paid service or make a purchase directly from us (rather than through a platform such as iOS or Android), you provide us with information related to the purchases you make and our payment processors with information such as your debit or credit card number or other financial information.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	When you participate in surveys, focus groups or market studies, you give us your insights into our products and services, responses to our questions and testimonials.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	If you contact our customer care team, we collect the information you give us during the interaction.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	If you share with us information about other people, we process this information on your behalf in order to complete your request.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Of course, we also process your chats with other members as well as the content you publish to operate and secure the services, and to keep our community safe.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            4. How We Use Information
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you and our community safe, and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons for which we use your information, together with practical examples.
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            A. To administer your account and provide our services to you
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Create and manage your account
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Provide you with customer support and respond to your requests
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Communicate with you about our services
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Personalize pricing, offer discounts and other promotions, and complete your transactions
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            B. To help you connect with other users and content
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Recommend you to other members and recommend other members to you
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Show members’ profiles to one another
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Enable members to search for and connect with members
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Recommend content to you
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Enable members to search for content
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            C. To provide offers and operate advertising and marketing campaigns
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Perform and measure the effectiveness of advertising campaigns on our services and marketing our services off our platform
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Communicate with you about products or services that we believe may interest you
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            D. To improve our services and develop new ones
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Administer focus groups, market studies and surveys
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Review interactions with customer care teams to improve our quality of service
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Understand how members typically use the services to improve them (for instance, we may decide to change the look and feel or even substantially modify a given feature based on how members react to it)
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Develop new features and services (for example, we may decide to build a new interests-based feature further to requests received from members)
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            E. To prevent, detect and fight fraud and other illegal or unauthorized activities
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Find and address ongoing, suspected or alleged violations of our Terms of Use, notably through the review of reports and interactions between members
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Better understand and design countermeasures against violations of our Terms of Use
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Retain data related to violations of our Terms of Use to address the violation and prevent against recurrences
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Enforce or exercise our rights, for example our rights set out in our Terms of Use
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Communicate to individuals who submit a report, including what we’ve done as a result of their submission
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            F. To ensure legal compliance
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Comply with legal requirements
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Assist law enforcement
            </Typography>

            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            To process your information as described in this Privacy Policy, we rely on the following legal bases:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Provide our service to you: The reason we process your information for purposes A and B above is to perform the contract that you have with us. For instance, as you go about using our service to build meaningful connections, we use your information to maintain your account and your profile, make it viewable to other members and recommend other members to you and to otherwise provide our free and paid features to you and other members.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Legitimate interests: We process your information for purposes C, D and E above, based on our legitimate interest. For instance, we analyze users’ behavior on our services to continuously improve our offerings, we suggest offers we think might interest you and promote our own services, we process information to help keep our members safe and we process data where necessary to enforce our rights, assist law enforcement and enable us to defend ourselves in the event of a legal action.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Comply with applicable laws and regulations: We process your information for purpose F above where it is necessary for us to comply with applicable laws and regulations and evidence our compliance with applicable laws and regulations. For example, we retain traffic data and data about transactions in line with our accounting, tax and other statutory data retention obligations and to be able to respond to valid access requests from law enforcement. We also keep data evidencing consents members give us and decisions they may have taken to opt-out of a given feature or processing.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Consent: If you choose to provide us with information that may be considered “special” or “sensitive” in certain jurisdictions, you’re consenting to our processing of that information in accordance with this Privacy Policy. From time to time, we may ask for your consent to collect specific information such as your precise geolocation or use your information for certain specific reasons. In some cases, you may withdraw your consent by adapting your settings (for instance in relation to the collection of our precise geolocation) or by deleting your content (for instance where you entered information in your profile that may be considered “special” or “sensitive”). In any case, you may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            5. How We Share Information
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Since our goal is to help you make meaningful connections, the main sharing of members’ information is, of course, with other members. We also share some types of members’ information with service providers and partners who assist us in operating the services, and, in some cases, legal authorities. Read on for more details about how your information is shared with others.
            </Typography>
            <Typography color="textSecondary" sx={{ marginBottom: '20px' }}>
            •	With other members
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You share information with other members when you voluntarily disclose information on the service (including your public profile). Please be careful with your information and make sure that the content you share is stuff that you’re comfortable being visible.
            If you choose to limit the audience for all or part of your profile or for certain content or information about you, then it will be visible according to your settings.
            If someone submits a report involving you (such as a claim you violated our Terms of Use), we may communicate to the reporter actions, if any, we took as a result of their report.
            </Typography>
            <Typography color="textSecondary" sx={{ marginBottom: '20px' }}>
            •	With our service providers and partners
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We use vendors to help us operate, distribute, market and improve our services, such as data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations. We also share information with vendors who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising vendors.
            We follow a strict vetting process prior to engaging any vendor or working with any partner. Our vendors and partners must agree to strict confidentiality obligations.
            </Typography>
            <Typography color="textSecondary" sx={{ marginBottom: '20px' }}>
           •	To enforce legal rights
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our members, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
            </Typography>
            <Typography color="textSecondary" sx={{ marginBottom: '20px' }}>
            •	With your consent or at your request
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            7. Your Rights and Choices
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We want you to be in control of your information, so we want to remind you of the following options and tools available to you:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Access / Update tools in the service. Tools and account settings can help you access, rectify or remove information that you provided to us and that’s associated with your account directly within the service. If you have any questions on those tools and settings, please contact us.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Device permissions. Mobile platforms can have permission systems for specific types of device data and notifications, such as phone contacts, pictures, location services, push notifications and advertising identifiers. You can change your settings on your device to either consent or oppose the collection or processing of the corresponding information or the display of the corresponding notifications. Of course, if you do that, certain services may lose functionality.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Uninstall. You can stop all information collection by an app by uninstalling it using the standard uninstall process for your device. Remember that uninstalling an app does NOT close your account. To close your account, please use the corresponding functionality on the service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Account closure. You can close your account by using the corresponding functionality directly on the service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We also want you to be aware of your privacy rights. Depending on where you live, you may have the right to:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Access/know. You may have the right to request a copy of the information we keep about you, and in certain circumstances to receive this in a portable format. You can exercise your right to access directly within the service by contacting us. 
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Delete/erase. You may request that we delete the personal information we keep about you. You can exercise your right to delete by contacting us. 
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Correct/rectify/update. You can correct most information you provided to us by editing your profile directly in the service. If you believe the information we hold about you is inaccurate, you may contact us to rectify it.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	Object/restrict. You may also have the right to object to or request that we restrict certain processing. To do so, please contact us.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            For your protection and the protection of all of our members, we may ask you to provide proof of identity before we can answer the above requests. Keep in mind, we may reject requests, including if we are unable to authenticate you, if the request is unlawful or invalid, or if it may infringe on trade secrets or intellectual property or the privacy or other rights of someone else. If you wish to receive information relating to another member, such as a copy of any messages you received from them through our service, the other member will have to contact us to provide their written consent before the information is released. We may also ask them to provide proof of identity before we can answer the request. Also, we may not be able to accommodate certain requests to object to or restrict the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth and thus cannot ensure that you are 18 years of age or older.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            8. How Long We Retain Your Information
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We keep your personal information only as long as it is on the platform. If you delete something, it is also deleted from our database.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            9. No Children Allowed
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Our services are restricted to individuals who are 18 years of age or older. We do not permit individuals under the age of 18 on our platform. If you suspect that a member is under the age of 18, please use the reporting mechanism available on the service.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Contact us
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            If you have any questions about this Policy, please contact us.
            </Typography>
            
        </Grid>

        <Grid display="flex" flexDirection="row" justifyContent="center" marginTop="50px">
          <Typography component={Link} to="/terms" color="textSecondary" marginRight="10px">Terms of Use</Typography>
          <Typography component={Link} to="/privacy" color="textSecondary" marginLeft="10px">Privacy Policy</Typography>
        </Grid>

        {/*<Grid>
        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center" spacing={isMobile? 1 : 5}
        sx={{ margin: '40px 0px 20px 0px' }}>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/about" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    About
                </Link>
            </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/contact" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Contact
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/terms" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
              <Link 
                to="/privacy" 
                style={{ color: 'Grey', textDecoration: 'none' }}
              >
                Privacy
              </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey'}}>
                <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: 'Grey', "textDecoration": "none"}}>Twitter</a>
              </Typography>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '30px 10px 30px 10px' : '30px 60px 30px 60px'}>        
        </Grid>

      </Grid>*/}

    </Grid>
  );
};

export default Privacy;
import React, { useState, useEffect } from 'react';
import { AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, Box, Tooltip, IconButton, TextField, InputAdornment, Grid, LinearProgress, Paper, CircularProgress, FormControlLabel,  Switch } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const SendMessage = ({ onSendMessage }) => {
    const [message, setMessage] = useState('');

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const handleSubmit = (event) => {
        event.preventDefault();
        onSendMessage(message);
        setMessage('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') { //keyCode 13 is just the enter key
          e.preventDefault();
          handleSubmit(e);
        }
      };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (message) ? setDisable(false) : setDisable(true);
    }, [message]);

    return (
        <Grid component="form" onSubmit={handleSubmit} sx={{ position: 'fixed', bottom: 60, width: isMobile ? '90%' : '50%', left: 0, right: 0, margin: 'auto'}}>
            <TextField autoFocus={!isMobile} fullWidth//={isMobile ? true : false} //style={ isMobile ? {width: '95%', minWidth: '95%', maxWidth: '95%', marginBottom:'2.5%', marginTop:'2.5%'} : { width: '50%', minWidth: '50%', maxWidth: '50%', marginBottom:'0.75%', marginTop:'0.75%', marginLeft: '180px' }}
          name="goal" size="large" label={message ? '' : 'Send a message'} InputLabelProps={{ shrink: false, style: { paddingLeft: '5px' } }} 
          value={message} multiline onChange={(e) => setMessage(e.target.value)} inputProps={{maxLength: 150, style: { paddingLeft: '5px' }}}
          sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35',
          borderRadius:'30px', borderColor: '#dddddd'},  '&:hover fieldset': {borderColor: '#79afdb'}}, '& .MuiInputBase-root': {
            background: 'rgba(254, 254, 252, 0.95)', backdropFilter:"blur(2px)", //'rgba(30, 30, 30, 1)',
            borderRadius: '30px',
            //paddingRight:'20px',
            //paddingLeft:'-20px'
          },}}
          onKeyPress={handleKeyPress}
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleSubmit} disabled={disable} style={disable ? {padding:'2px', marginRight:'0px'} : {backgroundColor: '#79afdb', color: 'white', padding:'2px', marginRight:'0px'}}>
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}>
          </TextField>
        </Grid>
    );
};

export default SendMessage;

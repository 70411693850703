import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Divider, Typography, Tabs, Tab, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getSimilarProfiles, getConnectionRequests, getRequests, connectProfiles } from '../../actions/profile';
import ProfileToMatch from './ProfileToMatch/ProfileToMatch'

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const ConnectionMatching = ({ }) => {
    const dispatch = useDispatch();
    const { profilesToMatch, isLoadingProfiles } = useSelector((state) => state.profile);
    const user = JSON.parse(localStorage.getItem('profile'));
    const [currentProfileIndex, setCurrentProfileIndex] = useState(0);

    const [value, setValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    useEffect(() => {
    if (user?.result?._id) {
        if (value === 0) {
            dispatch(getSimilarProfiles(user.result._id));
        } else if (value === 1) {
            dispatch(getConnectionRequests(user.result._id));
        }
    }
    }, [user?.result?._id, value]);

    const handleProfileAction = (action) => {
        // action can be 'connect' or 'discard'
        // Perform any action needed for connection or discarding
        dispatch(connectProfiles(user.result._id, profilesToMatch[currentProfileIndex].profile._id, action));

        // Move to next profile
        if (currentProfileIndex < profilesToMatch.length) {
            setCurrentProfileIndex(currentIndex => currentIndex + 1);
        } else {
            // No more profiles to show
            console.log("No more profiles");
        }
    };

    if (!user) {
        return (
          <Grid container justifyContent="center">
            <Typography variant="h6" sx={{marginTop: '40px'}}>Sign in to connect with people.</Typography>
          </Grid>
        );
      };

    return (
            <Grid container alignItems="stretch" spacing={0}>
                <Grid item xs={12} sm={12} md={12} display="flex" flexDirection="column" justifyContent={"center"} alignItems={isMobile ? "center" : "flex-start"}
                sx={{margin: '15px 0px 0px 0px'}}>
                    <Tabs value={value} onChange={handleChangeTab} sx={{'.MuiTabs-indicator': { backgroundColor: 'black' }, margin: isMobile ? '0px' : '2px 0px 0px 12px',  height: '40px', minHeight: '40px', maxHeight: '40px'}}>
                        <Tab 
                            label="Discover"
                            sx={(theme) => ({
                                textTransform: 'none', 
                                fontSize: '20px', 
                                //fontWeight: value === 0 ? 'bold' : 'normal',
                                //color: value === 0 ? 'black' : 'grey',
                                '&.Mui-selected': {
                                    fontWeight: 'bold',
                                    color: 'black',
                                },
                                marginRight: isMobile ? '20px' : '10px', 
                                height: '40px', 
                                minHeight: '40px', 
                                maxHeight: '40px', 
                                width: '95px', 
                                minWidth: '95px', 
                                maxWidth: '95px'
                            })}
                        />
                        <Tab 
                            label="Requests" 
                            sx={(theme) => ({
                                textTransform: 'none', 
                                fontSize: '20px', 
                                //fontWeight: value === 0 ? 'bold' : 'normal',
                                //color: value === 0 ? 'black' : 'grey',
                                '&.Mui-selected': {
                                    fontWeight: 'bold',
                                    color: 'black',
                                },
                                marginLeft: isMobile ? '20px' : '10px', 
                                height: '40px', 
                                minHeight: '40px', 
                                maxHeight: '40px', 
                                width: '95px', 
                                minWidth: '95px', 
                                maxWidth: '95px'
                            })}
                        />
                    </Tabs>
                </Grid>

                {isLoadingProfiles && (<CircularProgress />)}

                {!isLoadingProfiles && profilesToMatch.length > 0 && currentProfileIndex < profilesToMatch.length && (
                <Grid key={profilesToMatch[currentProfileIndex].profile._id} item xs={12} sm={12} md={12} lg={12}>
                    <ProfileToMatch
                        profile={profilesToMatch[currentProfileIndex].profile} 
                        onProfileAction={handleProfileAction}
                    />
                </Grid>
                )}
                {!isLoadingProfiles && value == 0 && profilesToMatch.length == 0 && (
                    <Grid container justifyContent="center">
                        <Typography sx={{marginTop: '40px', textAlign: 'center'}}>No people to connect with yet. Stay tuned, more people are joining the platform!</Typography>
                    </Grid>
                )}
                {!isLoadingProfiles && value == 1 && profilesToMatch.length == 0 && (
                    <Grid container justifyContent="center">
                        <Typography sx={{marginTop: '40px', textAlign: 'center'}}>Your connection requests will appear here.</Typography>
                    </Grid>
                )}
            </Grid>
    );
}

export default ConnectionMatching;
import { AUTH } from '../constants/actionTypes';
import * as api from '../api';

// function that returns an action
// action creators are asynchronous (we have to use redux tank) -> function that returns an async function with a dispatch

export const signin_old = (formData, history) => async (dispatch) => {
    try {
        //Sign in the user
        const { data } = await api.signIn(formData);

        dispatch({ type: AUTH, data });

        history.goBack(); // go to home page after Sign in
    } catch (error) {
        console.log(error);
    }
};

export const signup = (formData, history) => async (dispatch) => {
    try {
        //sign up the user..
        const { data } = await api.signUp(formData);

        dispatch({ type: AUTH, data });

        history.push('/profile/onboarding'); // go to following page after sign up
    } catch (error) {
        console.log(error);
    }
};

export const signin = (formData, history) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);

        dispatch({ type: AUTH, data });

        // Determine redirection based on whether it was a sign in or sign up
        if (data.newAccountCreated) {
            history.push('/profile/onboarding');
            window.location.reload();
        } else {
            history.goBack();
            window.location.reload();
        }
    } catch (error) {
        console.log(error);
    }
};

export const signinGoogleUser = (result, history) => async (dispatch) => {
    try {
        //Sign in the user
        const { data } = await api.signinGoogleUser(result);

        dispatch({ type: AUTH, data });

        if (data.newAccountCreated) {
            history.push('/profile/onboarding');
            window.location.reload();
        } else {
            history.goBack(); // if existing following, go to home page after Sign in
            window.location.reload();
        }
    } catch (error) {
        console.log(error);
    }
};
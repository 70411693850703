import React from 'react';
import { Container, Grid } from '@mui/material';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import '@fontsource/inter/400.css';
import "@fontsource/space-grotesk";
import "@dannymichel/proxima-nova";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GoogleOAuthProvider } from '@react-oauth/google';

const theme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {main: '#79afdb'}, //#90caf9
    background: {
        default: "#FEFEFc", // #FEFEF1
        paper: "#FEFEFc",
      },
    },
    typography: {
      fontFamily: [
        'Inter', // Proxima Nova // Space Grotesk
        'sans-serif',
      ].join(','),
      //fontSize: 15,
    },
});

import Navbar from './components/Navbar/Navbar';
import BottomBar from './components/BottomBar/BottomBar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import UserProfile from './components/UserProfile/UserProfile';
import UserList from './components/UserList/UserList';
import CreatePostPage from './components/createPostPage';
import EditPostPage from './components/EditPostPage';
import PostDetails from './components/PostDetails/PostDetails';
import EditProfile from './components/EditProfile/EditProfile';
import ExplorePage from './components/ExplorePage/ExplorePage';
import Onboarding from './components/Onboarding/Onboarding';
import ConnectionMatching from './components/ConnectionMatching/ConnectionMatching';
import Connections from './components/Connections/Connections';
import Chat from './components/Chat/Chat';
import AISearch from './components/AISearch/AISearch';
import Privacy from './components/Docs/Privacy';
import Terms from './components/Docs/Terms';
import Settings from './components/Settings/Settings';

const App = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });
    
    return(
        <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <GoogleOAuthProvider clientId="273393858926-k3vf9501s9ilrk759e8a9m5dk9hul86b.apps.googleusercontent.com">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={11.25} sm={11.25} md={6} xl={4}>
                <Navbar/>
                <Switch>
                    <Route path="/" exact component={() => (!user ? <Home /> : <Redirect to="/posts" />)} />
                    <Route path="/posts" exact component={Home} />
                    <Route path="/posts/search" exact component={Home} />
                    <Route path="/posts/category" exact component={Home} />
                    <Route path="/posts/saved" component={UserList} />
                    <Route path="/posts/circles" component={ExplorePage} />
                    <Route path="/posts/:id" component={PostDetails} />
                    <Route path="/profile/onboarding" component={Onboarding} />
                    <Route path="/profile/settings" component={Settings} />
                    <Route path="/profile/connect" component={ConnectionMatching} />
                    <Route path="/profile/aiconnect" component={AISearch} />
                    <Route path="/profile/chat" component={Connections} />
                    <Route path="/profile/discussion/:connectionId" component={Chat} />
                    <Route path="/profile/editprofile" component={EditProfile} />
                    <Route path="/profile/:id" component={UserProfile} />
                    <Route path="/create" component={CreatePostPage} />
                    <Route path="/edit" component={EditPostPage} />
                    <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/posts" />)} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/terms" component={Terms} />
                </Switch>
                {/*<AudioPlayer/>*/}
                <Grid marginBottom="80px"/>
                {( user?.result && (
                <BottomBar/>
                ) )}
            </Grid>
        </Grid>
        </LocalizationProvider>
        </GoogleOAuthProvider>
        </ThemeProvider>
        </BrowserRouter>
    )
};
// BrowserRouter: router implementation for HTML5 browser (use different url for different...)
// Switch: either show the home either show the auth
// first check if the user is logged in before showing Auth page

export default App;
import { FETCH_PROFILE, FETCH_EDIT_PROFILE, FETCH_SIMILAR_PROFILES, FETCH_CONNECTIONS, FETCH_CONVERSATION, START_LOADING, END_LOADING, START_LOADING_PROFILES, END_LOADING_PROFILES } from '../constants/actionTypes';

export default (state = { isLoading: true, isLoadingProfiles: false, profile: null, posts: [], profilesToMatch: [], connections: [], conversation: [] }, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        case START_LOADING_PROFILES:
            return { ...state, isLoadingProfiles: true }
        case END_LOADING_PROFILES:
            return { ...state, isLoadingProfiles: false }
        case FETCH_PROFILE:
            return { ...state, profile: action.payload.profile, posts: action.payload.posts };
        case FETCH_EDIT_PROFILE:
            const localStorageProfileData = JSON.parse(localStorage.getItem('profile'));
            localStorageProfileData.result = { ...action.payload }
            localStorage.setItem('profile', JSON.stringify({ result: localStorageProfileData.result, token: localStorageProfileData.token }));
            return { ...state, profile: action.payload };
        case FETCH_SIMILAR_PROFILES:
            return { ...state, profilesToMatch: action.payload.profilesToMatch };
        case FETCH_CONNECTIONS:
            return { ...state, connections: action.payload.connections };
        case FETCH_CONVERSATION:
            return { ...state, conversation: action.payload.conversation };
        default:
        return state;
    }
}
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #36d2f5 30%, #40f1b0 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const Settings = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"
    >

        <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
            Docs
        </Typography>

        <Grid display="flex" flexDirection="row" justifyContent="center" marginTop="20px">
          <Typography component={Link} to="/terms" color="textSecondary" marginRight="10px">Terms of Use</Typography>
          <Typography component={Link} to="/privacy" color="textSecondary" marginLeft="10px">Privacy Policy</Typography>
        </Grid>

    </Grid>
  );
};

export default Settings;
import React, { useState, useEffect } from "react";
import { Grid, Paper, AppBar, TextField, Button, Typography, Container, Tabs, Tab} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';

import Posts from '../Posts/Posts';
import Filters from '../Filters/Filters';

import SideBar from "../SideBar/SideBar";
import Pagination from '../Pagination/Pagination';
import LandingIntro from "../LandingIntro/LandingIntro";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from "./homeStyles";

const categories = [
    {value: 'Technology'},
    {value: 'Philosophy'},
    {value: 'Science'},
    {value: 'History'},
    {value: 'Statistics'},
    {value: 'Personal Development'},
    {value: 'Geography'},
  ];

const theme = createTheme();
const PREFIX = 'Home';
const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    inputField: `${PREFIX}-inputField`,
    searchButton: `${PREFIX}-searchButton`,
    categoryTitle: `${PREFIX}-categoryTitle`,
    allPostsTitle: `${PREFIX}-allPostsTitle`,
    categoryDescription: `${PREFIX}-categoryDescription`
};

// set up url search params to know on which page are we currently on and what search are we looking for
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Home = () => {
    const[setCurrentId] = useState(null); //const[currentId, setCurrentId] = useState(null);

    const dispatch = useDispatch();
    const query = useQuery(); // where we'll be getting our page info from
    const history = useHistory();
    const [page, setPage] = useState(query.get('page') || 1); // read our url and see if we have a page parameter in there, if so it's going to populate this variable
    const searchQuery = query.get('searchQuery');
    const categoryQuery = query.get('category');
    const { posts, isLoading } = useSelector((state) => state.posts);
    const user = JSON.parse(localStorage.getItem('profile'));
    const [userFollowing, setUserFollowing] = useState({
        followedCircles: user?.result?.followedCircles, followedTags: user?.result?.followedTags
    });
    const [metricFilter, setMetricFilter] = useState('trending');
    const [formatFilter, setFormatFilter] = useState('All');
    var [trickUseEffectTrigger, setTrickUseEffectTrigger] = useState(1);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const updatePage = (newPage) => {
        setPage(newPage);
    };
    
    const updateMetricFilter = (newMetricFilter) => {
        setMetricFilter(newMetricFilter);
    };

    const updateFormatFilter = (newFormatFilter) => {
        setFormatFilter(newFormatFilter);
    };

    const updateTrickUseEffectTrigger = () => {
        setTrickUseEffectTrigger(trickUseEffectTrigger + 1);
    };

    useEffect(() => {
        setMetricFilter('trending');
        setFormatFilter('All');
    }, [searchQuery, categoryQuery]);
    
    const circleListTabs = !user?.result ? categories.map((category) => category.value) : userFollowing.followedCircles;
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
    const handleCircleSelect = (receivedCircle) => {
        //setSelectedCircle(receivedCircle);
        if (receivedCircle === "All") {
            history.push(`/posts`);
        } else {
          history.push(`/posts/category?category=${receivedCircle}`);
          //setSelectedCircle(receivedCircle);
        }
      };

    return (
        <StyledGrow>
        {/* <Container maxWidth="xl"> */}
            <Grid className={classes.gridContainer} container justifyContent="space-between" alignItems="stretch" spacing={5} >

                <Grid item xs={12} sm={12} md={12} marginTop="20px" >

                    {( !user?.result && (
                        <Grid marginTop='10px' marginBottom={'-60px'}>
                        <LandingIntro/>
                        </Grid>
                    ) )}

                    {/* {( !searchQuery && (
                        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons={false}
                        sx={ isMobile ? {margin:'10px 10px 12px 0px', minHeight:'56px', maxHeight:'56px', alignItems:'center', borderBottom: `0.5px solid transparent`, borderTop: `0.5px solid transparent`} : {margin:'10px 10px 12px 0px', minHeight:'56px', maxHeight:'56px', alignItems:'center', borderBottom: `0.5px solid transparent`, borderTop: `0.5px solid transparent`}}
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        >
                            <Tab sx={(selectedTab === 0) ?
                                    {textTransform:'none', color:'black', marginLeft:'8px',
                                    borderRadius: '40px', backgroundColor: 'rgba(35, 35, 35, 0.05)', padding:'10px',
                                    minHeight:'30px', maxHeight:'30px', minWidth:'30px', overflow: 'hidden', '&:hover': {backgroundColor: 'rgba(35, 35, 35, 0.1)'},
                                    '&.Mui-selected': {color: 'black',backgroundColor: 'rgba(35, 35, 35, 0.05)',}} :
                                    {textTransform:'none', color:'black', marginLeft:'8px',
                                    borderRadius: '40px', backgroundColor: 'rgba(35, 35, 35, 0.05)', padding:'10px',
                                    minHeight:'30px', maxHeight:'30px', minWidth:'30px', overflow: 'hidden', '&:hover': {backgroundColor: 'rgba(35, 35, 35, 0.1)'},
                                    '&.Mui-selected': {color: 'black',backgroundColor: 'rgba(35, 35, 35, 0.05)',}}
                                }
                                key={"Home"}
                                label={<HomeOutlinedIcon style={{ fontSize: 20 }}/>}
                                onClick={() => handleCircleSelect("All")}
                            >
                                <HomeOutlinedIcon/>
                            </Tab>
                            {categories.map((category) => category.value)?.map((category) => (
                            <Tab sx={(selectedTab === categories.map((category) => category.value)?.indexOf(category) + 1) ?
                                    {textTransform:'none', color:'black', marginLeft:'8px',
                                    borderRadius: '40px', backgroundColor: 'rgba(35, 35, 35, 0.05)', padding:'10px',
                                    minHeight:'30px', maxHeight:'30px', minWidth:'30px', overflow: 'hidden', '&:hover': {backgroundColor: 'rgba(35, 35, 35, 0.1)'},
                                    '&.Mui-selected': {color: 'black',backgroundColor: 'rgba(35, 35, 35, 0.05)',}} :
                                    {textTransform:'none', color:'black', marginLeft:'8px',
                                    borderRadius: '40px', backgroundColor: 'rgba(35, 35, 35, 0.05)', padding:'10px',
                                    minHeight:'30px', maxHeight:'30px', minWidth:'30px', overflow: 'hidden', '&:hover': {backgroundColor: 'rgba(35, 35, 35, 0.1)'},
                                    '&.Mui-selected': {color: 'black',backgroundColor: 'rgba(35, 35, 35, 0.05)',}}
                                }
                                key={category}
                                label={category}
                                onClick={() => handleCircleSelect(category)}
                            >
                                {category}
                            </Tab>
                            ))}
                        </Tabs>
                    ) )} */}
                    {( user?.result && (
                    <Grid display="flex" flexDirection="row" justifyContent="space-between">
                    {( categoryQuery && (
                        <Typography className={classes.categoryTitle} variant="h6" sx={{ fontWeight: 'bold' }} >
                            {categoryQuery}
                        </Typography>
                    ) )}
                    {/*{( categoryQuery && (
                        <Typography className={classes.categoryDescription} sx={{ fontWeight: 'light' }}>
                            Discover the posts in the circle {categoryQuery} 
                        </Typography>
                    ) )}*/}
                    {( user?.result && !searchQuery && !categoryQuery && (
                        <Typography className={classes.allPostsTitle} variant="h6" sx={{ fontWeight: 'bold', marginBottom: '15px' }} >
                            Around you
                        </Typography>
                    ) )}
                    {( !user?.result && !searchQuery && !categoryQuery && (
                        <Typography className={classes.allPostsTitle} variant="h6" sx={{ fontWeight: 'bold', marginBottom: '15px' }} >
                            Posts on Cohuma
                        </Typography>
                    ) )}
                    {( searchQuery && (
                        <Typography className={classes.allPostsTitle} variant="h6" sx={{ fontWeight: 'bold', marginBottom: '15px' }} >
                            Results for "{searchQuery}"
                        </Typography>
                    ) )}
                    {/*<Filters metricFilter={metricFilter} formatFilter={formatFilter} updateMetricFilter={updateMetricFilter} updateFormatFilter={updateFormatFilter} updateTrickUseEffectTrigger={updateTrickUseEffectTrigger}/>*/}
                    </Grid>
                    ) )}
                    {( user?.result && (
                    <Posts posts={posts} isLoading={isLoading} setCurrentId={setCurrentId} />
                    ) )}
                    {/*{( !searchQuery && !categoryQuery && (*/}
                    <Container  maxWidth="sm">
                        <Paper className={classes.pagination} elevation={0}>
                            <Pagination page={page} updatePage={updatePage} metricFilter={metricFilter} formatFilter={formatFilter} searchQuery={searchQuery} categoryQuery={categoryQuery} trickUseEffectTrigger={trickUseEffectTrigger} />
                        </Paper>
                    {/*) )}*/}
                    </Container>
                </Grid>
                
            </Grid>
        {/* </Container> */}
    </StyledGrow>
    );
};

// component={Link} to="/" to point to home
// elevation property for nice shadow
// TextField is our search here

export default Home;
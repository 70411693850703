import React, { useState, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const MessageFeed = ({ messages, userId }) => {
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
    const lastMessageRef = useRef(null);

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const getRelativeDay = (timestamp) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
    
        const messageDate = new Date(timestamp);
        messageDate.setHours(0, 0, 0, 0);
    
        if (messageDate.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (messageDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else {
            return messageDate.toLocaleDateString('en-GB');
        }
    };    

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleListItemClick = (index) => {
        setSelectedMessageIndex(index === selectedMessageIndex ? null : index);
    };

    const isNewDay = (currentMessageTimestamp, previousMessageTimestamp) => {
        return getRelativeDay(currentMessageTimestamp) !== getRelativeDay(previousMessageTimestamp);
    };

    return (
        <Paper style={{ backgroundColor: 'transparent', overflow: 'hidden', marginBottom: '20px' }} elevation={0}>
            <List sx={{ bgcolor: 'background.paper' }}>
                {messages.map((message, index) => {
                    const isLastMessage = index === messages.length - 1;
                    const showDay = index === 0 || isNewDay(message.timestamp, index > 0 ? messages[index - 1].timestamp : null);

                    return (
                        <React.Fragment key={index}>
                            {showDay && (
                                <ListItem sx={{ justifyContent: 'center' }}>
                                    <Typography variant="body2" color="textSecondary" margin="-5px 0px 5px 0px">
                                        {getRelativeDay(message.timestamp)}
                                    </Typography>
                                </ListItem>
                            )}
                            <div onClick={() => handleListItemClick(index)} ref={isLastMessage ? lastMessageRef : null}>
                                <ListItem 
                                    sx={{
                                        backgroundColor: message.sender === userId ? '#c5e4fc' : '#eeeeee',
                                        borderRadius: '16px',
                                        marginLeft: message.sender === userId ? '20%' : 0,
                                        marginRight: message.sender !== userId ? '20%' : 0,
                                        width: '80%',
                                        marginBottom: '10px'
                                    }}
                                    elevation={0}
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={message.content}
                                    />
                                </ListItem>
                                {selectedMessageIndex === index && (
                                    <Grid container justifyContent={message.sender === userId ? "flex-end" : "flex-start"}
                                    sx={{marginBottom: '10px', marginTop: '-8px', marginRight: message.sender === userId ? '0px' : 0,
                                    marginLeft: message.sender !== userId ? '2px' : 0}}>
                                        <Grid item>
                                            <Typography variant="body2" color="textSecondary" fontSize="12px">
                                                {formatTime(message.timestamp)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </React.Fragment>
                    );
                })}
            </List>
        </Paper>
    );
};

export default MessageFeed;

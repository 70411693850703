import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextField, Button, Typography, Paper, Tabs, Tab, TabPanel, Box, Grid, Container, CircularProgress, Chip, InputAdornment, Accordion, AccordionSummary, AccordionDetails, IconButton, Divider, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

import { editProfile } from '../../actions/profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPaper from './onboardingStyles';

const theme = createTheme();
const PREFIX = 'Form';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    form: `${PREFIX}-form`,
    inputField: `${PREFIX}-inputField`,
    fileInput: `${PREFIX}-fileInput`,
    buttonSubmit: `${PREFIX}-buttonSubmit`,
    buttonCancel: `${PREFIX}-buttonCancel`,
};

const steps = [
    {
        label: 'Basic info',
        description:
          'An ad group contains one or more ads which target a shared set of keywords.',
      },
  {
    label: 'More about you',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Why are you here?',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Occupation and Education',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Picture and link',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
];

function calculateAge(birthdate) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

export default function TextMobileStepper() {
  const theme = useTheme();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const [userData, setUserData] = useState({
    //name: '', birthdate: '', location: 'Berlin 🇩🇪', bio: '', hobbies: '', goals: '', occupation: '', school: '', degree: '', profilePicture: '', link: '', lookingfor: '', activities: ''
    name: user?.result?.name, birthdate: user?.result?.birthdate ? dayjs(user?.result?.birthdate) : null, location: 'Berlin 🇩🇪', bio: user?.result?.bio, hobbies: user?.result?.hobbies, goals: user?.result?.goals, occupation: user?.result?.occupation, school: user?.result?.school, degree: user?.result?.degree, profilePicture: user?.result?.profilePicture, link: user?.result?.link, lookingfor: user?.result?.lookingfor, activities: user?.result?.activities
  });
  const [age, setAge] = React.useState(user?.result?.birthdate ? calculateAge(user?.result?.birthdate) : '');
  const [selectedImage, setSelectedImage] = useState(user?.result?.profilePicture ? user.result.profilePicture : null);
  const fileInputRef = useRef(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // for image
  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    setSelectedImage(selectedImage);
    setUserData({ ...userData, profilePicture: selectedImage})
  };

  const handleDeleteImage = () => {
        if (selectedImage instanceof File) {
            URL.revokeObjectURL(selectedImage); // Clean up the object URL if it's a new file
            setSelectedImage(null);
        }
    };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isProfilePicPresent = user?.result?.profilePicture || selectedImage instanceof File;
    if (!isProfilePicPresent) {
        alert('Please upload a profile picture.');
        return;
    }

    const formData = new FormData();
    formData.append('name', userData.name);
    formData.append('birthdate', userData.birthdate);
    formData.append('location', userData.location);
    formData.append('bio', userData.bio);
    formData.append('hobbies', userData.hobbies);
    formData.append('goals', userData.goals);
    formData.append('occupation', userData.occupation);
    formData.append('school', userData.school);
    formData.append('degree', userData.degree);
    if (selectedImage instanceof File) {
        formData.append('profilePicture', selectedImage);
    }
    formData.append('link', userData.link);
    formData.append('lookingfor', userData.lookingfor);
    formData.append('activities', userData.activities);
    
    setLoadingUpload(true);
    dispatch(
        editProfile(user?.result?._id, formData)
    );
    setTimeout(() => {
        history.push(`/profile/${user?.result?._id}`);
    }, 2000);
    //clear();
             
    };

  const handleCancel = () => {
    history.goBack();
  };

  const [disable, setDisable] = useState(true); // disable submit button if no input
      

  useEffect(() => {
    const isProfilePicPresent = user?.result?.profilePicture || selectedImage instanceof File;
    const isFormDataComplete = userData.name && userData.birthdate && userData.location && userData.bio && userData.hobbies && userData.goals && userData.lookingfor && userData.activities;

    setDisable(!(isFormDataComplete && isProfilePicPresent));
    }, [userData, selectedImage, user?.result?.profilePicture]);

  const handleDateChange = (newValue) => {
    const age = calculateAge(newValue);
    setUserData({ ...userData, birthdate: newValue})
    setAge(age);
  };

  return (
    <StyledPaper elevation={0}>
    <Box>
    <Typography variant="h6" sx={{textAlign: 'center', fontWeight: 'bold', margin: '0px 16px 0px 16px', paddingTop: '20px'}}>
        Configure your profile
    </Typography>
    <MobileStepper
      variant="dots"
      steps={5}
      position="fixed"
      activeStep={activeStep}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === 4}
        sx={{textTransform: 'none', fontSize: '16px'}}>
          Next
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}
        sx={{textTransform: 'none', fontSize: '16px'}}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          //alignItems: 'center',
          //height: 50,
          //pl: 2,
          //bgcolor: 'background.default',
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{fontWeight: 'bold', marginTop: '10px', marginBottom: '25px'}}>
            {steps[activeStep].label}
        </Typography>
        {(activeStep === 0) && (
        <Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    First name
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="name" fullWidth required
                value={userData.name}
                InputLabelProps={{ shrink: false }}
                label={userData.name ? "" : "What's your first name?"}
                multiline
                inputProps={{maxLength: 120}}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })} >
                </TextField>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Birth date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb"
                label="Birth date">
                    <DatePicker required value={userData.birthdate}
                    //onChange={(newValue) => setUserData({ ...userData, birthdate: newValue })}
                    onChange={handleDateChange}
                    sx={{width: '100%', '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderRadius: '16px'
                    },}}}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Location
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="location" fullWidth required
                value={userData.location}
                //value="Berlin"
                label={userData.location ? "" : "Location"}
                multiline
                inputProps={{maxLength: 120}}
                //onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                >
                </TextField>
            </Grid>
        </Grid>
        )}
        {(activeStep === 1) && (
        <Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Introduce yourself
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="bio" fullWidth required
                value={userData.bio}
                InputLabelProps={{ shrink: false }}
                label={userData.bio ? "" : "Write a few words about yourself"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, bio: e.target.value })} >
                </TextField>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    What do you like in life?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="hobbies" fullWidth required
                value={userData.hobbies}
                InputLabelProps={{ shrink: false }}
                label={userData.hobbies ? "" : "Hobbies, interests, pleasures"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, hobbies: e.target.value })} >
                </TextField>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    What are your goals?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="goals" fullWidth required
                value={userData.goals}
                InputLabelProps={{ shrink: false }}
                label={userData.goals ? "" : "Goals in life"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, goals: e.target.value })} >
                </TextField>
            </Grid>
            {/*<Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Values
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="values" fullWidth required
                value={userData.values}
                InputLabelProps={{ shrink: false }}
                label={userData.values ? "" : "Values"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, values: e.target.value })} >
                </TextField>
            </Grid>*/}
        </Grid>
        )}
        {(activeStep === 2) && (
        <Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Why are you here?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="lookingfor" fullWidth required
                value={userData.lookingfor}
                InputLabelProps={{ shrink: false }}
                label={userData.lookingfor ? "" : "Making friends..."}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, lookingfor: e.target.value })} >
                </TextField>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    What activities would you like to do?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="activities" fullWidth required
                value={userData.activities}
                InputLabelProps={{ shrink: false }}
                label={userData.activities ? "" : "Activities"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, activities: e.target.value })} >
                </TextField>
            </Grid>
        </Grid>
        )}
        {(activeStep === 3) && (
        <Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    What is your occupation?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="occupation" fullWidth //required
                value={userData.occupation}
                InputLabelProps={{ shrink: false }}
                label={userData.occupation ? "" : "Occupation"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, occupation: e.target.value })} >
                </TextField>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Where did you study?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="school" fullWidth //required
                value={userData.school}
                InputLabelProps={{ shrink: false }}
                label={userData.school ? "" : "School/Uni"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, school: e.target.value })} >
                </TextField>
            </Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    What did you study?
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="degree" fullWidth //required
                value={userData.degree}
                InputLabelProps={{ shrink: false }}
                label={userData.degree ? "" : "Field(s)"}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, degree: e.target.value })} >
                </TextField>
            </Grid>
        </Grid>
        )}
        {(activeStep === 4) && (
        <Grid>
            <Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Profile picture
                </Typography>
                <Grid container display="flex" flexDirection="column" justifyContent="flex-start" sx={{ margin: '0px 0px 20px 0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                        <div>
                        {selectedImage && (
                            <img 
                                src={selectedImage instanceof File ? URL.createObjectURL(selectedImage) : selectedImage}
                                alt="Profile"
                                style={{ width: 'auto', height: '50px', margin: '0px 22px 0px 0px', borderRadius: 8 }} 
                            />
                        )}
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<ImageOutlinedIcon />}
                            onClick={handleClick}
                            sx={{ textTransform: 'none', color: '#707070', borderColor: '#bbbbbb', borderRadius: '12px', fontSize: '16px', "&:hover": {borderColor: '#707070'}}}
                            size="medium"
                            disableElevation
                        >
                            {selectedImage ? 'Change picture' : 'Add a picture'}
                        </Button>
                        {selectedImage && (
                        <IconButton
                            onClick={handleDeleteImage}
                            color="#707070"
                            sx={{ marginLeft: 0.5 }} // Add some spacing between buttons
                        >
                            <CloseIcon />
                        </IconButton>
                        )}
                    </div>
                </Grid>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Useful link
                </Typography>
                <TextField className={classes.inputField} variant="outlined" name="link" fullWidth //required
                value={userData.link}
                InputLabelProps={{ shrink: false }}
                label={userData.link ? "" : "Personal website, Twitter, Linkedin..."}
                multiline
                inputProps={{maxLength: 300}}
                onChange={(e) => setUserData({ ...userData, link: e.target.value })} >
                </TextField>
            </Grid>
            <Grid display='flex' justifyContent='flex-end' alignItems='center'>
                    {(loadingUpload && (<CircularProgress />))}
                    {(!loadingUpload && (
                    <Button
                        className={classes.buttonCancel} onClick={handleCancel}
                        sx={{ color: 'black', backgroundColor: 'transparent', borderColor: 'black'}}
                        variant="contained" size="small" disableElevation>
                            Cancel
                    </Button>
                    ))}
                    {(!loadingUpload && (
                    <Button
                        className={classes.buttonSubmit}
                        sx={{ color: 'white', borderColor: 'black'}}
                        variant="contained" size="small" disableElevation type="submit" disabled={disable}>
                            Save
                    </Button>
                    ))}
                </Grid>
            {/*<Grid sx={{marginBottom: '20px'}}>
                <Typography sx={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Prompts
                </Typography>
            </Grid>*/}
        </Grid>
        )}
        </form>
      </Paper>

      {/*<Divider sx={{margin:'20px 0px 20px 0px'}} />

      <Paper elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          marginBottom: '30px'
        }}>
        <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '16px', color: '#909090', marginTop: '10px', marginBottom: '10px'}}>
            Preview
        </Typography>
        <Grid display="flex" flexDirection="row">
            <div>
            {selectedImage && (
                <img 
                src={URL.createObjectURL(selectedImage)} 
                alt="Uploaded"
                style={{ maxWidth: '200px', height: 'auto', margin: '0px 20px 0px 0px', borderRadius: 8 }} 
                />
            )}
            </div>
            <Grid display="flex" flexDirection="column">
                {(userData.name) && (<Typography variant="h5" sx={{fontWeight: 'bold'}}>
                    {userData.name}
                </Typography>)}
                {(userData.birthdate && !isNaN(age)) && (<Grid display="flex" flexDirection="row" alignItems="center" sx={{marginTop: '10px', color: '#424242'}}>
                    <CakeOutlinedIcon sx={{marginRight: '8px', marginTop: '-5px'}} />
                    <Typography>
                        {age}
                    </Typography>
                </Grid>)}
                {(userData.name && userData.location) && (<Grid display="flex" flexDirection="row" alignItems="center" sx={{marginTop: '10px', color: '#424242'}}>
                    <LocationOnOutlinedIcon sx={{marginRight: '8px'}} />
                    <Typography>
                        {userData.location}
                    </Typography>
                </Grid>)}
            </Grid>
        </Grid>
        {(userData.bio) && (<Typography variant="h6" sx={{fontSize: '18px', fontWeight: 'bold', marginTop: '25px'}}>
            Bio
        </Typography>)}
        {(userData.bio) && (<Typography sx={{marginTop: '5px'}}>
            {userData.bio}
        </Typography>)}
        {(userData.occupation) && (<Grid display="flex" flexDirection="row" alignItems="center" sx={{marginTop: '10px', color: '#424242'}}>
            <WorkOutlineOutlinedIcon sx={{marginRight: '8px'}} />
            <Typography sx={{marginTop: '5px'}}>
                {userData.occupation}
            </Typography>
        </Grid>)}
        {(userData.degree && userData.school) && (<Grid display="flex" flexDirection="row" sx={{marginTop: '10px', color: '#424242'}}>
            <SchoolOutlinedIcon sx={{marginRight: '8px'}} />
            <Typography>
                {userData.degree} at {userData.school}
            </Typography>
        </Grid>)}
        {(userData.link) && (<Grid display="flex" flexDirection="row" sx={{marginTop: '10px', color: '#424242'}}>
            <LinkOutlinedIcon sx={{marginRight: '8px'}} />
            <Link href={userData.link} target="_blank" rel="noopener noreferrer">
                <Typography>
                    {userData.link.slice(0, 50)}
                </Typography>
            </Link>
        </Grid>)}
        {(userData.lookingfor) && (<Typography variant="h6" sx={{fontSize: '18px', fontWeight: 'bold', marginTop: '25px'}}>
            I'm here for
        </Typography>)}
        {(userData.lookingfor) && (<Typography sx={{marginTop: '5px'}}>
            {userData.lookingfor}
        </Typography>)}
        {(userData.activities) && (<Typography sx={{marginTop: '5px'}}>
            Activities: {userData.activities}
        </Typography>)}
        {(userData.hobbies) && (<Typography variant="h6" sx={{fontSize: '18px', fontWeight: 'bold', marginTop: '25px'}}>
            I like
        </Typography>)}
        {(userData.hobbies) && (<Typography sx={{marginTop: '5px'}}>
            {userData.hobbies}
        </Typography>)}
        {(userData.goals) && (<Typography variant="h6" sx={{fontSize: '18px', fontWeight: 'bold', marginTop: '25px'}}>
            My goals in life
        </Typography>)}
        {(userData.goals) && (<Typography sx={{marginTop: '5px'}}>
            {userData.goals}
        </Typography>)}
            </Paper>*/}

      {/*<Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
        <Typography>{steps[activeStep].description}</Typography>
    </Box>*/}
      
    </Box>
    </StyledPaper>
  );
}

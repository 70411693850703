import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Button, TextField, InputAdornment, Hidden } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledToolbar from "./searchBarStyles";

const theme = createTheme();
const PREFIX = 'SearchBar';
const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    inputField: `${PREFIX}-inputField`,
    searchButton: `${PREFIX}-searchButton`,
};

const SearchBar = ({onSearchExpanded}) => {
  const history = useHistory();
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const searchRef = useRef(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchExpanded(false);
        onSearchExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    }, []);

    const handleSearchToggle = () => {
        setIsSearchExpanded(true);
        onSearchExpanded(true);
    };
  
    const searchPost = () => {
        if(search.trim()) { // trim() to avoid empty spaces
            history.push(`/posts/search?searchQuery=${search || 'none'}`); // go to post url
        } else {
            history.push('/');
        }
    };
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') { //keyCode 13 is just the enter key
            searchPost();
        }
    };

    const handleSearchClose = () => {
        setIsSearchExpanded(false);
        onSearchExpanded(false);
        setSearchValue('');
    };

    return (
        <StyledToolbar sx={isMobile ? { minWidth:'0px', margin: '0px 0px 0px 0px'} : { maxWidth:'600px', position: 'fixed', left: 0, right: 0, margin: 'auto'}} className={classes.appBarSearch}>
            <Hidden mdUp>
            {!isSearchExpanded ? (
                <Button
                className={classes.searchButton}
                aria-label="search"
                onClick={handleSearchToggle}
                >
                <SearchIcon style={{ fontSize: 28 }}/>
                </Button>
            ) : (
                <IconButton
                edge="start"
                //color="inherit"
                size="small"
                aria-label="close"
                onClick={handleSearchClose}
                sx={{position: 'fixed', top: 10, left: 10}}
                >
                <ArrowBackIosNewOutlinedIcon style={{ color: 'darkGrey' }} />
            </IconButton>
            )}
            {/*<IconButton
                edge="start"
                //color="inherit"
                aria-label="close"
                onClick={handleSearchClose}
                sx={{marginRight:'4px'}}
                >
                <ArrowBackIosNewOutlinedIcon style={{ color: 'lightGrey' }} />
            </IconButton>*/}
            </Hidden>
            <Hidden smDown>
            <TextField size="small" className={classes.inputField} style={{margin:'0px 0'}} onKeyPress={handleKeyPress} 
                            name="searchContent" variant="outlined" label={search ? "" : "Search"}
                            fullWidth value={search} onChange={(e) => setSearch(e.target.value)}
                            InputLabelProps={{style: { marginLeft: 34 },shrink: false}}
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: 'darkGrey', marginLeft:'4px' }}/></InputAdornment>)}} />
            </Hidden>
            {isSearchExpanded && (
            <Hidden smUp>
                <div ref={searchRef}>
                <TextField size="small" className={classes.inputField} style={{ position: 'fixed', top: 7.5, left: 50, right: 20, margin: 'auto'}} onKeyPress={handleKeyPress} 
                            name="searchContent" variant="outlined" autoFocus label={search ? "" : "Search"}
                            value={search} onChange={(e) => setSearch(e.target.value)}
                            InputLabelProps={{style: { marginLeft: 34 },shrink: false}}
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: 'darkGrey', marginLeft:'4px' }}/></InputAdornment>)}} />
                </div>
            </Hidden>
            )}
        </StyledToolbar>
    );
};

export default SearchBar;

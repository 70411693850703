import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

export default function SimpleBottomNavigation() {
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('profile'));
  const userId = user?.result?._id;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const paths = ['/profile/connect', '/posts',
  //'/create',
  '/profile/chat', `/profile/${userId}`];

  // Find the index of the current path in the paths array
  const currentTabIndex = paths.indexOf(location.pathname);

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={0}>
      <BottomNavigation
        showLabels
        value={currentTabIndex}
        onChange={(event, newValue) => {
          history.push(paths[newValue]);
        }}
        sx={{background: 'rgba(254, 254, 252, 0.95)', backdropFilter:"blur(2px)"}}
      >
        <BottomNavigationAction label={isMobile ? "" : "Connect"} icon={<GroupsOutlinedIcon />} />
        <BottomNavigationAction label={isMobile ? "" : "Feed"} icon={<DynamicFeedOutlinedIcon />} />
        {/*<BottomNavigationAction label={isMobile ? "" : "Post"} icon={<AddBoxOutlinedIcon />} sx={{margin: isMobile ? '0px -10px 0px -10px' : '0px -30px 0px -30px'}} />*/}
        <BottomNavigationAction label={isMobile ? "" : "Chat"} icon={<ChatOutlinedIcon />} />
        <BottomNavigationAction label={isMobile ? "" : "Profile"} icon={<AccountBoxOutlinedIcon />} />
      </BottomNavigation>
    </Paper>
);
}


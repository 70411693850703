import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Divider, Typography, Tabs, Tab, Box, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getSimilarProfiles, getConnectionRequests, getAISearchProfiles, getRequests, connectProfiles } from '../../actions/profile';
import ProfileToMatch from "../ConnectionMatching/ProfileToMatch/ProfileToMatch";
import AISearchBar from "./AISearchBar";

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const AISearch = ({ }) => {
    const dispatch = useDispatch();
    const { profilesToMatch, isLoadingProfiles } = useSelector((state) => state.profile);
    const user = JSON.parse(localStorage.getItem('profile'));
    const [currentProfileIndex, setCurrentProfileIndex] = useState(0);
    const [request, setRequest] = useState('');

    const [value, setValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const handleSendSearchRequest = async (requestContent) => {
        // update is done in state
        dispatch(getAISearchProfiles(user.result._id, requestContent));
        setRequest(requestContent);
      };

    //useEffect(() => {
    //if (user?.result?._id) {
    //    if (value === 0) {
    //        dispatch(getSimilarProfiles(user.result._id));
    //    } else if (value === 1) {
    //        dispatch(getConnectionRequests(user.result._id));
    //    }
    //}
    //}, [user?.result?._id, value]);

    const handleProfileAction = (action) => {
        // action can be 'connect' or 'discard'
        // Perform any action needed for connection or discarding
        dispatch(connectProfiles(user.result._id, profilesToMatch[currentProfileIndex].profile._id, action));

        // Move to next profile
        if (currentProfileIndex < profilesToMatch.length) {
            setCurrentProfileIndex(currentIndex => currentIndex + 1);
        } else {
            // No more profiles to show
            console.log("No more profiles");
        }
    };

    if (!user) {
        return (
          <Grid container justifyContent="center">
            <Typography variant="h6" sx={{marginTop: '40px'}}>Sign in to connect with people.</Typography>
          </Grid>
        );
      };

    return (
            <Grid container alignItems="stretch" spacing={0} display="flex" flexDirection="column">

            <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    AI Connect
                </Typography>
                <Box
                    sx={{
                    background: '#e03f3f',
                    color: 'white',
                    fontSize: '0.75rem',
                    padding: '0 4px',
                    borderRadius: '4px',
                    marginLeft: '4px', // Adds a little space between the text and the label
                    alignSelf: 'flex-end'
                    }}
                >
                    Beta
                </Box>
                </Box>
                <Grid sx={{margin: '20px 0px 20px 0px', width: '100%'}}>
                    <AISearchBar onSendSearchRequest={handleSendSearchRequest} />
                </Grid>

                { !request && (
                <Grid container>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            startIcon={<ArrowForwardIcon />} 
                            sx={{ textTransform: 'none', fontSize: '16px', backgroundColor: 'transparent', textAlign: 'left', color: 'Grey', borderRadius: "16px",
                            '&:hover': {
                                backgroundColor: '#eeeeee',
                            } }}
                            disableElevation
                            onClick={() => handleSendSearchRequest("Having a coffee with someone who likes startups")}
                        >
                            ☕️ Having a coffee with someone who likes startups
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            startIcon={<ArrowForwardIcon />} 
                            sx={{ textTransform: 'none', fontSize: '16px', backgroundColor: 'transparent', textAlign: 'left', color: 'Grey', borderRadius: "16px",
                            '&:hover': {
                                backgroundColor: '#eeeeee',
                            } }}
                            disableElevation
                            onClick={() => handleSendSearchRequest("A cool person to have a drink and eventually party")}
                        >
                            🍻 A cool person to have a drink and eventually party
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            startIcon={<ArrowForwardIcon />} 
                            sx={{ textTransform: 'none', fontSize: '16px', backgroundColor: 'transparent', textAlign: 'left', color: 'Grey', borderRadius: "16px",
                            '&:hover': {
                                backgroundColor: '#eeeeee',
                            } }}
                            disableElevation
                            onClick={() => handleSendSearchRequest("Buddy to explore local art galleries")}
                        >
                            🎨 A buddy to explore local art galleries
                        </Button>
                    </Grid>
                    {/* Add more buttons here with different phrases */}
                </Grid>)}

                { !request && (
                    <Typography color="textSecondary" sx={{fontSize: '13px', textAlign: 'center', margin: '100px 0px 0px 0px'}}>The results of AI Connect will improve as more people join the platform.</Typography>
                )}

                { request && (
                    <Typography sx={{fontSize: '18px', fontWeight: 'bold', margin: '0px 0px 0px 16px'}}>Results for</Typography>
                )}
                { request && (
                    <Typography sx={{fontSize: '18px', margin: '0px 0px 0px 16px'}}>"{request}"</Typography>
                )}

                {isLoadingProfiles && (<CircularProgress sx={{marginTop: '20px'}}/>)}

                { request && !isLoadingProfiles && profilesToMatch.length > 0 && currentProfileIndex < profilesToMatch.length && (
                <Grid key={profilesToMatch[currentProfileIndex].profile._id} item xs={12} sm={12} md={12} lg={12}>
                    <ProfileToMatch
                        profile={profilesToMatch[currentProfileIndex].profile} 
                        onProfileAction={handleProfileAction}
                    />
                </Grid>
                )}
                {request && !isLoadingProfiles && value == 0 && profilesToMatch.length == 0 && (
                    <Grid container justifyContent="center">
                        <Typography sx={{marginTop: '40px', textAlign: 'center'}}>No people to connect with yet. Stay tuned, more people are joining the platform!</Typography>
                    </Grid>
                )}
                {request && !isLoadingProfiles && value == 1 && profilesToMatch.length == 0 && (
                    <Grid container justifyContent="center">
                        <Typography sx={{marginTop: '40px', textAlign: 'center'}}>Your connection requests will appear here.</Typography>
                    </Grid>
                )}
            </Grid>
    );
}

export default AISearch;
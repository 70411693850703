import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, Box, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import decode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';

import talaxyLogo from '../../images/co_logo.PNG';
import twitterLogo from '../../images/twitter_logo.png';

import SearchBar from "./SearchBar/searchBar";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledAppBar from "./navbarStyles";

const theme = createTheme();
const PREFIX = 'Navbar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonLogin: `${PREFIX}-buttonLogin`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};


const Navbar = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const userId = user?.result?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const handleSearchExpanded = (value) => {
        setIsSearchExpanded(value);
      // Perform any necessary actions with the received prop
    };

    // for menulist on desktop (options: profile or logout)
    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };  
    const closeMenu = () => {
        setAnchor(null);
    };

    // for menu on mobile
    const [anchorMobile, setAnchorMobile] = useState(null);
    const openMenuMobile = (event) => {
        setAnchorMobile(event.currentTarget);
    }; 
    const closeMenuMobile = () => {
        setAnchorMobile(null);
    };

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        history.push('/');
        window.location.reload();
        setAnchor(null);
        setAnchorMobile(null);
        setUser(null);
    }

    const goToProfile = () => {
        history.push(`/profile/${userId}`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToUserList = () => {
        history.push(`/posts/saved`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToUploadPage = () => {
        history.push(`/create`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToAISearch = () => {
        history.push(`/profile/aiconnect`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToSettings = () => {
        history.push(`/profile/settings`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToMatching = () => {
        history.push(`/profile/connect`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToExploreCircles = () => {
        history.push(`/posts/circles`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToTopContributors = () => {
        history.push(`/posts/topcontributors`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToTwitterPage = () => {
        window.open('https://twitter.com/circletree_app');
        setAnchor(null);
        setAnchorMobile(null);
    }

    const goToEditFollowing = () => {
        history.push(`/profile/editfollowing`);
        setAnchor(null);
        setAnchorMobile(null);
    }

    useEffect(() => {
        const token = user?.token;

        if (token) {
            const decodedToken = decode(token);

            if (decodedToken.exp * 1000 < new Date().getTime()) logout(); // log out if token expired, i.e. if logging in > 1h
        }

        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location]);
    
    return (
      <Box marginBottom={isMobile ? '60px' : '60px'}>
      <StyledAppBar className={classes.appBar} elevation={0} position="fixed" sx={{background: 'rgba(254, 254, 252, 0.95)', backdropFilter:"blur(2px)"}}>
          {!isSearchExpanded && (
          <Link to="/" className={classes.brandContainer} style={{ textDecoration: 'none', color: 'black' }}>
              <img className={classes.image} src={talaxyLogo} alt="talaxy" ></img>
              {/*<Typography sx={{ fontSize: 24 }} >💫</Typography>*/}
          </Link>
          )}

          {/*{!isMobile && (
          <SearchBar onSearchExpanded={handleSearchExpanded}/>
          )}*/}
          
          <Toolbar className={classes.toolbar}>
            {/*{isMobile && (
            <SearchBar onSearchExpanded={handleSearchExpanded}/>
            )}*/}

            {/*{user && !isMobile ? (
                <Button className={classes.buttonCreate} component={Link} to="/create"
                variant="contained" size="small" disableElevation
                sx={{ color: 'white', borderColor: 'none' }}>
                    Post
                </Button>
            ) : null }*/}

            {/*{user && !isMobile ? (
                <Tooltip title={<Typography>Saved</Typography>} arrow>
                <Button className={classes.logout}
                sx={{ marginLeft: '20px' }}
                onClick={goToUserList}>
                    <BookmarksOutlinedIcon sx={{ fontSize: 26 }} />
                </Button>
                </Tooltip>
            ) : null }*/}
            
            {user && !isMobile && (
                <div>
                    <Tooltip title={<Typography>Menu</Typography>} arrow>
                    <Button className={classes.logout} sx={{ marginLeft: '20px' }}
                    onClick={openMenu} size="large"> <MenuIcon sx={{ fontSize: 28 }} /> </Button>
                    </Tooltip>
                    <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted 
                    sx={{ '& .MuiPaper-root': {width: '150px'} }}>
                        <Typography className={classes.userName} variant="subtitle1" sx={{fontWeight: 'bold', margin: '5px 0px 5px 0px'}}> &nbsp;&nbsp;&nbsp;{user.result.name}&nbsp;&nbsp;&nbsp;</Typography>
                        {/*<MenuItem onClick={goToMatching} sx={{marginBottom: '5px'}}> <GroupsOutlinedIcon/>&nbsp;&nbsp;Connect</MenuItem>
                        <MenuItem onClick={goToMatching} sx={{marginBottom: '5px'}}> <ChatOutlinedIcon/>&nbsp;&nbsp;Chat</MenuItem>
                        <MenuItem onClick={goToMatching} sx={{marginBottom: '5px'}}> <DynamicFeedOutlinedIcon/>&nbsp;&nbsp;Feed</MenuItem>*/}
                        <MenuItem onClick={goToUploadPage} sx={{marginBottom: '5px'}}> <AddBoxOutlinedIcon/>&nbsp;&nbsp;Post</MenuItem>
                        <MenuItem onClick={goToAISearch} sx={{marginBottom: '5px'}}> <SearchOutlinedIcon/>&nbsp;&nbsp;AI Connect</MenuItem>
                        <MenuItem onClick={goToUserList} sx={{marginBottom: '5px'}}> <BookmarksOutlinedIcon/>&nbsp;&nbsp;Saved</MenuItem>
                        <MenuItem onClick={goToProfile} sx={{marginBottom: '5px'}}> <AccountBoxOutlinedIcon/>&nbsp;&nbsp;Profile</MenuItem>
                        <MenuItem onClick={goToSettings}> <SettingsOutlinedIcon/>&nbsp;&nbsp;Docs</MenuItem>
                        {/*<MenuItem onClick={goToEditFollowing}> <StarOutlineOutlinedIcon/>&nbsp;&nbsp;Following</MenuItem>*/}
                        <MenuItem onClick={logout} sx={{marginBottom: '5px'}}> <LogoutOutlinedIcon/>&nbsp;&nbsp;Log out</MenuItem>
                    </Menu>
                </div>
            )}
            {!user && !isMobile && (
                    <Button className={classes.buttonLogin} component={Link} to="/auth"
                    sx={{ color: 'white', borderColor: 'black', fontWeight: 'bold' }}
                    variant="contained" size="small" disableElevation
                    >Sign in</Button>
            )}

            {user && isMobile && !isSearchExpanded && (
            <div>    
                <Button className={classes.logout}
                    sx={{  }}
                    onClick={openMenuMobile} size="large"> <MenuIcon sx={{ fontSize: 28 }}/> </Button>
                    <Menu open={Boolean(anchorMobile)} anchorEl={anchorMobile} onClose={closeMenuMobile} keepMounted
                    sx={{ '& .MuiPaper-root': {width: '150px'} }}>
                        <Typography className={classes.userName} variant="subtitle1" sx={{fontWeight: 'bold'}}> &nbsp;&nbsp;&nbsp;{user?.result?.name}&nbsp;&nbsp;&nbsp;</Typography>
                        {/*<MenuItem onClick={goToMatching}> <GroupsOutlinedIcon/>&nbsp;&nbsp;Connect</MenuItem>
                        <MenuItem onClick={goToMatching} sx={{marginBottom: '5px'}}> <ChatOutlinedIcon/>&nbsp;&nbsp;Chat</MenuItem>
                        <MenuItem onClick={goToMatching} sx={{marginBottom: '5px'}}> <DynamicFeedOutlinedIcon/>&nbsp;&nbsp;Feed</MenuItem>*/}
                        <MenuItem onClick={goToUploadPage}> <AddBoxOutlinedIcon/>&nbsp;&nbsp;Post</MenuItem>
                        <MenuItem onClick={goToAISearch}> <SearchOutlinedIcon/>&nbsp;&nbsp;AI Connect</MenuItem>
                        <MenuItem onClick={goToUserList}> <BookmarksOutlinedIcon/>&nbsp;&nbsp;Saved</MenuItem>
                        <MenuItem onClick={goToProfile}> <AccountBoxOutlinedIcon/>&nbsp;&nbsp;Profile</MenuItem>
                        <MenuItem onClick={goToSettings}> <SettingsOutlinedIcon/>&nbsp;&nbsp;Docs</MenuItem>
                        {/*<MenuItem onClick={goToEditFollowing}> <StarOutlineOutlinedIcon/>&nbsp;&nbsp;Following</MenuItem>*/}
                        {/*<MenuItem onClick={goToExploreCircles}> <TravelExploreOutlinedIcon/>&nbsp;&nbsp;Explore circles</MenuItem>*/}
                        {/*<MenuItem onClick={goToTopContributors}> <LeaderboardOutlinedIcon/>&nbsp;&nbsp;Contributors</MenuItem>*/}
                        {/*<MenuItem onClick={goToTwitterPage}> <img src={twitterLogo} height="20px" alt="twitter"/>&nbsp;&nbsp;Contact us</MenuItem>*/}
                        <MenuItem onClick={logout}> <LogoutOutlinedIcon/>&nbsp;&nbsp;Log out</MenuItem>
                    </Menu>
            </div>
            )}
            {!user && isMobile && !isSearchExpanded && (
                <Button className={classes.buttonLogin} component={Link} to="/auth"
                sx={{ color: 'white', borderColor: 'black', fontWeight: 'bold' }}
                variant="contained" size="small" disableElevation
                >Sign in</Button>
            )}

          </Toolbar>
      </StyledAppBar>
      </Box>
    );
};

// component={Link} to="/" to point to home or history.push('/') to return to home
// JSON.parse(localStorage.getItem('')) to retrieve something from the local storage
// JSON.parse(localStorage.getItem('profile')) is the user

export default Navbar;

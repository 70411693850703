import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar, Avatar, Tooltip, Link, Tabs, Tab, Box } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import MessageFeed from './MessageFeed';
import SendMessage from './SendMessage';

import { getUserProfile, getConversation, sendChatMessage, deleteConnection } from '../../actions/profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const Chat = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { connectionId } = useParams();
    const { profile } = useSelector((state) => state);
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const [messages, setMessages] = useState(profile.conversation?.messages || []); // This would be fetched from your server

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
        setAnchor(event.currentTarget);
      };
    const closeMenu = () => {
      setAnchor(null);
    };

    useEffect(() => {
        dispatch(getUserProfile(connectionId));
      }, [connectionId]);

    useEffect(() => {
      dispatch(getConversation(userId, connectionId));
    }, [connectionId]);
    
    useEffect(() => {
        setMessages(profile.conversation.messages)
      }, [profile.conversation.messages]);

    const handleSendMessage = async (newMessageContent) => {
        // Implement logic to send message to server and update message list
        const newMessage = {
            sender: userId,
            conversationId: profile.conversation._id,
            content: newMessageContent,
        };
        // update is done in state
        const newConversationMessages = await dispatch(sendChatMessage(newMessage));
    };

    const handleDeleteChat = () => {
      dispatch(deleteConnection(userId, connectionId, history));
    };

    if (!profile.profile) {
        return (
          <Grid container justifyContent="center" marginTop="10px">
          <Typography variant="h6">No chat with this person.</Typography>
          </Grid>
        );
      };

      if (!Array.isArray(profile.conversation?.participants) || !profile.conversation.participants.includes(connectionId)) {
        return (
          <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" sx={{margin: '40px 0px 10px 0px'}}>Loading...</Typography>
          <CircularProgress />
          </Grid>
        );
      };

    return (
        <Paper elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          //marginBottom: '30px'
        }}>
            <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="center"
            sx={{ position: 'fixed', top: isMobile ? 55 : 60, zIndex: 2, backgroundColor: 'inherit', width: isMobile ? '87.5%' : '50%', background: 'rgba(254, 254, 252, 0.95)', backdropFilter:"blur(2px)" }}>
            <Link component={RouterLink} to={`/profile/${profile.profile._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Grid 
                    display="flex" 
                    flexDirection="row" 
                    alignItems="center" 
                    marginBottom="15px"
                    marginTop="15px"
                    sx={{
                        '&:hover .name-hover-effect': {
                            color: '#79afdb' // Apply hover effect to children with specific class
                        }
                    }}
                >
                    <Avatar src={profile.profile.profilePicture} alt={profile.profile.name} variant="rounded"/>
                    <Typography 
                        variant="h5" 
                        className="name-hover-effect" // Add a class for the hover effect
                        sx={{ fontWeight: 'bold', marginLeft: '10px' }}
                    >
                        {profile.profile.name}
                    </Typography>
                </Grid>
            </Link>
            <Button style={{color: 'grey', minWidth: '40px', maxWidth: '40px'}} size="small" onClick={openMenu}>
                <MoreHorizIcon fontSize="medium" />
            </Button>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                <div>
                <MenuItem onClick={handleDeleteChat} sx={{padding: '10px', margin: '-5px 3px -5px 3px'}}>
                  <DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete connection
                </MenuItem>
                </div>
            </Menu>
            </Grid>
            
            <Grid marginTop="40px">
            {messages && messages.length > 0 ? (
                <MessageFeed messages={messages} userId={userId} />
            ) : (
                <Typography sx={{ marginLeft: '10px', marginTop: '20px' }}>
                    Start the conversation
                </Typography>
            )}
            </Grid>
            
            <SendMessage onSendMessage={handleSendMessage} />
        </Paper>
    );
};

export default Chat;

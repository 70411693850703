import React, { useEffect } from 'react';
import { useState } from 'react';
import { Typography, CircularProgress, Divider, Button, Menu, MenuItem, Grid, CardActions, Card, Accordion, AccordionSummary, AccordionDetails, Snackbar } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams, useHistory, Link } from 'react-router-dom';
import { ReactTinyLink } from 'react-tiny-link';
import useMediaQuery from '@mui/material/useMediaQuery';
import parse from 'html-react-parser';

import { getPost } from '../../actions/posts';
import { deletePost, likePost, downvotePost, addPostToUserList, createAISummary } from '../../actions/posts';

import CommentSection from './CommentSection/CommentSection';
import SideBar from "../SideBar/SideBar";
import SuggestTagsPopper from './SuggestTagsPopper/SuggestTagsPopper';

import evotreeLogo from '../../images/circletree_logo_v4.png';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPaper from './postDetailsStyles';

const theme = createTheme();
const PREFIX = 'PostDetails';

const classes = {
  media: `${PREFIX}-media`,
  mediaBook: `${PREFIX}-mediaBook`,
  card: `${PREFIX}-card`,
  section: `${PREFIX}-section`,
  details: `${PREFIX}-details`,
  moreOptions: `${PREFIX}-moreOptions`,
  categoryLink: `${PREFIX}-categoryLink`,
  description: `${PREFIX}-description`,
  cardActions: `${PREFIX}-cardActions`,
  cardButton: `${PREFIX}-cardButton`,
  imageSection: `${PREFIX}-imageSection`,
  recommendedPosts: `${PREFIX}-recommendedPosts`,
  loadingPaper: `${PREFIX}-loadingPaper`
};

function getMostRecurrentElements(array) {
  // Create an object to track the frequency of each element
  const frequencyMap = {};
  // Count the frequency of each element in the array
  array.forEach((element) => {
    if (frequencyMap[element]) {
      frequencyMap[element]++;
    } else {
      frequencyMap[element] = 1;
    }
  });
  // Sort the unique elements based on their frequency in descending order
  const sortedElements = Object.keys(frequencyMap).sort(
    (a, b) => frequencyMap[b] - frequencyMap[a]
  );
  // Return the top 3 most recurrent elements
  return sortedElements.slice(0, 3);
}


const PostDetails = () => {
  const { post, posts, isLoading } = useSelector((state) => state?.posts); // get posts data
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [likes, setLikes] = useState(post?.likes);
  const [downvotes, setDownvotes] = useState(post?.downvotes);
  const [inList, setInList] = useState(post?.inList);
  const userId = user?.result?._id;
  const hasLikedPost = likes?.find((like) => like === userId);
  const hasDownvotedPost = downvotes?.find((downvote) => downvote === userId);
  const hasPostInList = inList?.find((id) => id === userId);
  const userInUploads = post?.creator.includes(userId);
  const [anchor, setAnchor] = useState(null);
  const [noteExpanded, setNoteExpanded] = useState(false);
  const handleNoteExpanded = () => {
      setNoteExpanded(!noteExpanded);
  };
  const [showSnackbarCopyLink, setShowSnackbarCopyLink] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const { id } = useParams();

  // get the data for a single post
  useEffect(() => {
    dispatch(getPost(id));
  }, [id]); //happens whenever the id of the post changes

  useEffect(() => {
    if (post?.format === "Book" && !post.aiSummary) {
      dispatch(createAISummary(id))
    };
  }, [id]);

  useEffect(()=>{
    setLikes(post?.likes);
  }, [post?.likes]);

  useEffect(()=>{
    setDownvotes(post?.downvotes);
  }, [post?.downvotes]);

  useEffect(()=>{
    setInList(post?.inList);
  }, [post?.inList]);

  const goToHomepage = () => {
    history.push('/');
  };

  const formatLogo = () => {
    if (post.format === "Post") {
      // if no likes: outlined button; if likes: different possibilities with the number
      return <img src={evotreeLogo} height="18px" alt="circletree" style={{ opacity: 0.8, marginRight:'-1px' }} />
    };
    if (post.format === "Video") {
      // if no likes: outlined button; if likes: different possibilities with the number
      return <img src={youtubeLogo} height="15px" alt="twitter" style={{ filter: 'brightness(0.7)' }} />
    };
    if (post.format === "Book") {
        // if no likes: outlined button; if likes: different possibilities with the number
        return <MenuBookOutlinedIcon fontSize="small" style={{ marginBottom: '2px' }} />;
    };
    if (post.format === "Podcast") {
        // if no likes: outlined button; if likes: different possibilities with the number
        return <PodcastsIcon fontSize="small" style={{ marginBottom: '2px' }} />;
    };
    if (post.format === "Article") {
        // if no likes: outlined button; if likes: different possibilities with the number
        return <ArticleOutlinedIcon fontSize="small" style={{ marginBottom: '2px' }} />;
    };
  };

  if (!post) {
    return (
    <div>
      <Typography variant="h6">No post with this id.</Typography>
      <Button onClick={goToHomepage}>Go to Home page</Button>
    </div>
    )
  };

  if (isLoading) {
    return (
      <CircularProgress/>
    );
  };

  function YouTubeViewer({ videoLink }) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px', marginBottom:'10px' }}>
        {/* Embed YouTube video */}
        <iframe 
          width={isMobile ? "100%" : "80%"}
          height={isMobile ? "200px" : "300px"}
          src={`https://www.youtube.com/embed/${extractVideoId(videoLink)}`}
          title="YouTube Video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  };
  // Helper function to extract video ID from YouTube link
  function extractVideoId(link) {
    const regex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})/i;
    const match = link.match(regex);
    return match && match[4] ? match[4] : '';
  }

  function PodcastPlayer({ podcastLink }) {
    const getPlatformFromLink = (link) => {
      if (link.includes('spotify')) {
        return 'spotify';
      }
      if (link.includes('google.com')) {
        return 'googlePodcasts';
      }
      if (link.includes('apple.com')) {
        return 'applePodcasts';
      }
      return null;
    };
    const getEmbedUrl = () => {
      const platform = getPlatformFromLink(podcastLink);
      if (platform === 'spotify') {
        return `https://open.spotify.com/embed/track/${podcastLink}`;
      }
      if (platform === 'googlePodcasts') {
        return `https://www.google.com/podcasts?feed=${encodeURIComponent(podcastLink)}`;
      }
      if (platform === 'applePodcasts') {
        return `https://embed.podcasts.apple.com/${podcastLink}`;
      }
      return null;
    };
    const platform = getPlatformFromLink(podcastLink);
    if (platform === 'applePodcasts') {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px', marginBottom:'10px' }}>
          {getEmbedUrl() && (
            <iframe
              title="Podcast Player"
              src={getEmbedUrl()}
              width="100%"
              height="450px"
              frameBorder="0"
              //scrolling="no"
            />
          )}
        </div>
      );
    }
    if (platform === 'googlePodcasts') {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px', marginBottom:'10px' }}>
          <Button onClick={openGooglePodcasts} sx={{textTransform:'none', borderRadius:'15px', color: 'Grey'}}>
            <div display='flex'>
              <Typography> Listen on </Typography>
              <img src={googlePodcastsLogo} height="45px" alt="Google Podcasts" />
            </div>
          </Button>
        </div>
    )}
    if (platform === 'spotify') {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px', marginBottom:'10px' }}>
          <Button onClick={openGooglePodcasts} sx={{textTransform:'none', borderRadius:'15px', color: 'Grey'}}>
            <div display='flex'>
              <Typography> Listen on </Typography>
              <img src={spotifyLogo} height="45px" alt="Spotify" />
            </div>
          </Button>
        </div>
    )}
  };

  const openGooglePodcasts = () => {
    window.open(post.link, '_blank');
  };

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  
  const closeMenu = () => {
    setAnchor(null);
  };

  const handleEdit = () => {
    history.push({pathname:'/edit', data: post._id  });
  };

  const handleDelete = () => {
    dispatch(deletePost(post._id, history));
  };

  const handleReport = () => {
    dispatch(closeMenu());
  };

  const handleLike = async () => {
    dispatch(likePost(post._id));

    if(hasLikedPost) {
        setLikes(likes.filter((id) => id !== userId));
    } else {
        setLikes([...likes, userId]);
    }
  };

  const Likes = () => {
    if (likes?.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
        return likes.find((like) => like === userId)
            ? (
            <><WbSunnyIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} />&nbsp;{likes.length}&nbsp;</>
        ) : (
            <><WbSunnyOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} />&nbsp;{likes.length}&nbsp;</>
        );
    }

    return <><WbSunnyOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} />&nbsp;</>;
  };

  // to have the downvote button colored directly, not after several seconds (after having the downvote in the database), for better user experience
  const handleDownvote = async () => {
    dispatch(downvotePost(post._id));

    if(hasDownvotedPost) {
        setDownvotes(downvotes.filter((id) => id !== userId));
    } else {
        setDownvotes([...downvotes, userId]);
    }
  };

  const Downvotes = () => {
      if (downvotes?.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
          return downvotes.find((downvote) => downvote === userId)
              ? (
              <><ThumbDownIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
          ) : (
              <><ThumbDownOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
          );
      }

      return <><ThumbDownOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} /></>;
  };

  const handleAddToList = async () => {
    dispatch(addPostToUserList(post._id));

    if(hasPostInList) {
        setInList(post.inList.filter((id) => id !== userId));
    } else {
        setInList([...post.inList, userId]);
    }
  };

  const AddToList = () => {
    if (inList?.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
        return inList.find((id) => id === userId)
            ? (
            <><BookmarkOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
        ) : (
            <><BookmarkBorderOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
        );
    }

    return <><BookmarkBorderOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} /></>;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://www.circletree.io/posts/${post._id}`);
    setShowSnackbarCopyLink(true);
  };
  const handleCloseSnackbarCopyLink = () => {
    setShowSnackbarCopyLink(false);
  };

  return (
  <Grid container justifyContent="space-between" alignItems="stretch" spacing={5} >
    <Grid item xs={12} sm={12} md={12} container justifyContent="center" alignItems="center" marginTop="5px" marginBottom="20px" >
    <Grid item xs={12} sm={12} md={12} >
      <StyledPaper style={{ padding: '0px', borderRadius: '15px' }} elevation={0}>
        <div className={classes.card}>
          <div className={classes.section}>
            <div className={classes.details}>
              <Link to={`/profile/${post.creator}`} className={classes.categoryLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography sx={{ fontWeight: 'light', marginRight: '0px' }} variant="body2" alignItems="center">{post.name}</Typography>
              </Link>
              {/*<Link to={`/posts/category?category=${post.category}`} className={classes.categoryLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography sx={{ fontWeight: 'light' }} variant="body2">{post.category}</Typography>
              </Link>*/}
              {/*<Typography sx={{ fontWeight: 'light' }} variant="body2" color="textSecondary">{Math.max(1, Math.round(post.message.length / 4 / 200))} min read</Typography>*/}
              {/*<Typography sx={{ fontWeight: 'light', marginRight: '0px' }} variant="body2" color="textSecondary" alignItems="center">{moment(post.createdAt).format("MMM DD")}</Typography>*/}
              <Typography sx={{ fontWeight: 'light', marginRight: '0px' }} variant="body2" color="textSecondary" alignItems="center">{moment(post.createdAt).fromNow()}</Typography>
              <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={openMenu}>
                <MoreHorizIcon fontSize="medium" />
              </Button>
              <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                {(userInUploads) && (
                  <div>
                    {/*<{(post.type !== 'resource') && (
                    <MenuItem onClick={handleEdit}><EditOutlinedIcon/>&nbsp;Edit</MenuItem>
                    )}*/}
                    <MenuItem onClick={handleDelete}><DeleteOutlinedIcon/>&nbsp;Delete</MenuItem>
                  </div>
                )}
                {(userId !== post?.creator) && (
                  <div>
                    <MenuItem onClick={handleReport}><FlagCircleOutlinedIcon/>&nbsp;Report</MenuItem>
                  </div>
                )}
              </Menu>
            </div>


            {(post.type === 'post') && (
            <div>
              <Typography gutterBottom variant="h6" component="h2" sx={{ fontWeight: 'bold', padding: '0px 8px 6px 8px', }}>{post.title}</Typography>
              {(post.image) && (
              <div className={classes.imageSection}>
                <img className={classes.media} src={post.image} />
              </div>
              )}
              {/*<Grid display="flex" flexDirection="row" alignItems="center" justifyContent='space-between'>
                <Typography className={classes.description} variant="body2" color="lightGrey" component="p" sx={{ fontWeight: 'light' }}>
                  {getMostRecurrentElements(post.tags).slice(0, 2).map((tag, index) => (
                    <span key={index}>
                    #{tag}{' '}
                    </span>
                  ))}
                </Typography>
              </Grid>*/}
              <Typography className={classes.description} gutterBottom variant="body2" component="p" sx={{ fontWeight: 'light', marginTop:'10px' }}>
                {post.message.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </div>
            )}
            

            <CardActions className={classes.cardActions}>
              <Button className={classes.cardButton} size="small"
              sx={{ borderColor: 'white' }}
              disabled={!user?.result} onClick={handleLike}>
                <Likes />
              </Button>
              <Button className={classes.cardButton} size="small"
              sx={{ borderColor: 'white' }}
              disabled={!user?.result} onClick={handleAddToList}>
                <AddToList />
              </Button>
              <Button className={classes.cardButton} size="small"
              sx={{ borderColor: 'white' }}
              disabled={!user?.result} onClick={handleDownvote}>
                <Downvotes />
              </Button>
              <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleCopyLink}>
                    <ShareOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}} />
                </Button>
                <Snackbar
                    open={showSnackbarCopyLink}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbarCopyLink}
                    message="Link copied to clipboard"
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                />
            </CardActions>

            <Divider style={{ margin: '10px 0px 30px 0px' }} />
            
            <CommentSection post={post} />
          {/* { <Divider style={{ margin: '20px 0' }} /> */}
          </div>

        </div>

      </StyledPaper>
    </Grid>
    </Grid>
  </Grid>
  );
};

export default PostDetails;

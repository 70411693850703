import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar, Avatar, Tooltip, Link, Tabs, Tab } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import NightlifeOutlinedIcon from '@mui/icons-material/NightlifeOutlined';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getUserProfile } from '../../../actions/profile';
import Posts from '../../Posts/Posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from './profileToMatchStyles';

const theme = createTheme();
const PREFIX = 'UserProfile';

const classes = {
  profileHead: `${PREFIX}-profileHead`,
  pagination: `${PREFIX}-pagination`,
  gridContainer: `${PREFIX}-gridContainer`,
  profilePostsTitle: `${PREFIX}-profilePostsTitle`,
  editButton: `${PREFIX}-editButton`,
};

function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const ProfileToMatch = ({ profile, onProfileAction }) => {
    //const { profile } = useSelector((state) => state);
    //const { profile: postsState } = useSelector((state) => state);
    const [age, setAge] = React.useState(calculateAge(profile?.birthdate));
    const posts = useSelector(state => state.profile.posts);
    const isLoading = useSelector(state => state.profile.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const profileId = profile?._id;
    //const { id } = useParams();

    const [value, setValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true
    });

    // get the data and posts of a single user
    useEffect(() => {
      if (profileId) {
        dispatch(getUserProfile(profileId));
      }
    }, [profileId]);
    
    // adapt age when id changes
    useEffect(() => {
      if (profile?.birthdate) {
        setAge(calculateAge(profile.birthdate));
      }
    }, [profile?.birthdate]);

    const handleConnect = () => {
      onProfileAction('connect');
      window.scrollTo(0, 0);
    };

    const handleDiscard = () => {
      onProfileAction('discard');
      window.scrollTo(0, 0);
    };


    if (!profile) {
      return (
        <Grid container justifyContent="center" marginTop="10px">
        <Typography variant="h6">No profile with this id.</Typography>
        </Grid>
      );
    };

    return (
      <StyledGrow>
          <Grid className={classes.gridContainer} container justifyContent="space-between" alignItems="stretch" spacing={5} >
            <Grid item xs={12} sm={12} md={12} marginBottom="40px" marginTop="10px">
            <Paper elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px 10px 16px 10px'
                //marginBottom: '30px'
              }}>
              <Grid display="flex" justifyContent={isMobile ? "" : "center"}>
                {profile.profilePicture && isMobile && (
                    <img
                    src={profile.profilePicture}//{URL.createObjectURL(profile.profilePicture)} 
                    //alt="Uploaded"
                    style={{ maxWidth: isMobile ? '100%' : '400px', height: 'auto', margin: '0px 0px 20px 0px', borderRadius: 8 }} 
                    />
                )}
              </Grid>
              <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Grid display="flex" flexDirection="row" alignItems="flex-start">
                  {profile.profilePicture && !isMobile && (
                      <img
                      src={profile.profilePicture}//{URL.createObjectURL(profile.profilePicture)} 
                      //alt="Uploaded"
                      style={{ maxWidth: isMobile ? '100%' : '400px', height: 'auto', margin: '0px 20px 0px 0px', borderRadius: 8 }} 
                      />
                  )}
                  <Grid display="flex" flexDirection="column">
                      {(profile.name) && (<Typography variant="h5" sx={{fontWeight: 'bold'}}>
                          {profile.name}
                      </Typography>)}
                      {(profile.birthdate && !isNaN(age)) && (<Grid display="flex" flexDirection="row" alignItems="center" sx={{marginTop: '5px', color: '#424242'}}>
                          <CakeOutlinedIcon sx={{marginRight: '8px', marginTop: '-5px', fontSize: '18px'}} />
                          <Typography sx={{fontSize: '15px'}}>
                              {age}
                          </Typography>
                      </Grid>)}
                      {(profile.name && profile.location) && (<Grid display="flex" flexDirection="row" alignItems="center" sx={{marginTop: '5px', color: '#424242'}}>
                          <LocationOnOutlinedIcon sx={{marginRight: '8px', marginTop: '-2px', fontSize: '18px'}} />
                          <Typography sx={{fontSize: '15px'}}>
                              {profile.location}
                          </Typography>
                      </Grid>)}
                    </Grid>
                  </Grid>

                  {(userId !== profileId) && (
                  <Button
                  //onClick={handleEditProfile}
                  sx={{ textTransform: 'none', fontWeight: 'bold', color: 'white', backgroundColor: 'black', borderRadius: '20px',
                  position: 'fixed', zIndex: 999, bottom: '70px', right: isMobile ? '20px' : '37.5%',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
                  "&:hover": { background: "#424242"}}}
                  variant="contained"
                  size="large"
                  type="submit"
                  onClick={handleConnect}
                  >
                    <FavoriteBorderOutlinedIcon sx={{marginRight: '6px'}}/> Connect
                  </Button>
                  )}
                  {(userId !== profileId) && (
                  <Button
                  //onClick={handleEditProfile}
                  sx={{ textTransform: 'none', fontWeight: 'bold', color: 'black', backgroundColor: 'white', borderRadius: '20px',
                  position: 'fixed', zIndex: 999, bottom: '70px', left: isMobile ? '20px' : '37.5%',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
                  "&:hover": { background: "#cccccc"}}}
                  variant="contained"
                  size="large"
                  type="submit"
                  onClick={handleDiscard}
                  >
                    <CloseOutlinedIcon sx={{marginRight: '6px'}}/> Discard
                  </Button>
                  )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} display="flex" flexDirection="column" //justifyContent={isMobile ? "center" : "flex-start"} alignItems={isMobile ? "center" : "flex-start"}
              sx={{margin: '20px 0px 20px 0px', borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChangeTab} sx={{height: '40px', minHeight: '40px', maxHeight: '40px'}}>
                  <Tab label="Card" sx={{textTransform: 'none', fontSize: '18px', fontWeight: 'bold', marginRight: isMobile ? '10px' : '10px', height: '40px', minHeight: '40px', maxHeight: '40px', width: '50px', minWidth: '50px', maxWidth: '50px'}}/>
                  <Tab label="Posts" sx={{textTransform: 'none', fontSize: '18px', fontWeight: 'bold', marginLeft: isMobile ? '10px' : '10px', height: '40px', minHeight: '40px', maxHeight: '40px', width: '50px', minWidth: '50px', maxWidth: '50px'}}/>
                </Tabs>
              </Grid>

              {(value==0) && (
              <Grid>
              {/*{(profile.bio) && (<Typography variant="h6" sx={{fontSize: '18px', fontWeight: 'bold', marginTop: '30px'}}>
                  Bio
              </Typography>)}*/}
              <Paper sx={{padding: '20px', borderRadius: '16px', border: '1px solid #eeeeee', marginTop: '5px', boxShadow: '0px 0px 4px rgba(0,0,0,0.1)'}}>
              {(profile.bio) && (
              <Typography sx={{textAlign: 'justify'}}>
                  {profile.bio}
              </Typography>)}
              {(profile.occupation) && profile.occupation !== 'undefined' && (
              <Grid display="flex" flexDirection="row" alignItems="center" sx={{marginTop: '5px', color: '#282828'}}>
                  <WorkOutlineOutlinedIcon sx={{marginRight: '8px', marginTop: '2px', fontSize: '18px'}} />
                  <Typography sx={{marginTop: '5px', fontSize: '15px'}}>
                      {profile.occupation}
                  </Typography>
              </Grid>)}
              {(profile.degree && profile.school && profile.degree !== 'undefined' && profile.school !== 'undefined') && (
              <Grid display="flex" flexDirection="row" sx={{marginTop: '5px', color: '#282828'}}>
                  <SchoolOutlinedIcon sx={{marginRight: '8px', marginTop: '2px', fontSize: '18px'}} />
                  <Typography sx={{fontSize: '15px'}}>
                      {profile.degree} at {profile.school}
                  </Typography>
              </Grid>)}
              {(profile.link && profile.link !== 'undefined') && (
              <Grid display="flex" flexDirection="row" sx={{marginTop: '5px', color: '#282828'}}>
                  <LinkOutlinedIcon sx={{marginRight: '8px', marginTop: '2px', fontSize: '18px'}} />
                  <Link href={profile.link} target="_blank" rel="noopener noreferrer">
                      <Typography sx={{fontSize: '15px'}}>
                          {profile.link.slice(0, 50)}
                      </Typography>
                  </Link>
              </Grid>)}
              </Paper>
              <Grid/>
              {(profile.lookingfor) && (
              <Paper sx={{padding: '20px', borderRadius: '16px', border: '1px solid #eeeeee', marginTop: '25px', boxShadow: '0px 0px 4px rgba(0,0,0,0.1)'}}>
                <Typography variant="h6" sx={{fontSize: '17px', fontWeight: 'bold'}}>
                  <TravelExploreOutlinedIcon sx={{fontSize: '20px', marginBottom: '-4px', marginRight: '3px'}}/> I'm here for
                </Typography>
                <Typography sx={{marginTop: '5px'}}>
                    {profile.lookingfor}
                </Typography>
              </Paper>)}
              {(profile.activities) && (
              <Paper sx={{padding: '20px', borderRadius: '16px', border: '1px solid #eeeeee', marginTop: '25px', boxShadow: '0px 0px 4px rgba(0,0,0,0.1)'}}>
                <Typography variant="h6" sx={{fontSize: '17px', fontWeight: 'bold'}}>
                  <NightlifeOutlinedIcon sx={{fontSize: '20px', marginBottom: '-4px', marginRight: '3px'}}/> Activities I'd like to do
                </Typography>
                <Typography sx={{marginTop: '5px'}}>
                    {profile.activities}
                </Typography>
              </Paper>)}
              {(profile.hobbies) && (
              <Paper sx={{padding: '20px', borderRadius: '16px', border: '1px solid #eeeeee', marginTop: '25px', boxShadow: '0px 0px 4px rgba(0,0,0,0.1)'}}>
                <Typography variant="h6" sx={{fontSize: '17px', fontWeight: 'bold'}}>
                  <FavoriteBorderOutlinedIcon sx={{fontSize: '20px', marginBottom: '-4px', marginRight: '3px'}}/> I like
                </Typography>
                <Typography sx={{marginTop: '5px'}}>
                    {profile.hobbies}
                </Typography>
              </Paper>)}
              {(profile.goals) && (
              <Paper sx={{padding: '20px', borderRadius: '16px', border: '1px solid #eeeeee', marginTop: '25px', boxShadow: '0px 0px 4px rgba(0,0,0,0.1)'}}>
                <Typography variant="h6" sx={{fontSize: '17px', fontWeight: 'bold'}}>
                  <TrackChangesOutlinedIcon sx={{fontSize: '20px', marginBottom: '-4px', marginRight: '3px'}}/> My goals in life
                </Typography>
                <Typography sx={{marginTop: '5px'}}>
                    {profile.goals}
                </Typography>
              </Paper>)}
              </Grid>
              )}
            </Paper>
              {(value==1) && (
              <Grid>
                <Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '-20px' }} >
                  Last posts
                </Typography>
                <Posts posts={posts} isLoading={isLoading} />
              </Grid>
              )}
            </Grid>
          </Grid>
      </StyledGrow>
    );

};

export default ProfileToMatch;
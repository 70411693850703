import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Grid, CircularProgress, Divider, Button, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';

import { getConnections, deleteConnection } from '../../actions/profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const getConciseTimeDifference = (timestamp) => {
    const now = moment();
    const then = moment(timestamp);
    const duration = moment.duration(now.diff(then));

    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();

    if (days >= 1) {
        return `${Math.floor(days)}d`;
    } else if (hours >= 1) {
        return `${Math.floor(hours)}h`;
    } else if (minutes >= 1) {
        return `${Math.floor(minutes)}m`;
    } else {
        return 'Now';
    }
};

export const Connections = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { connections, isLoading } = useSelector((state) => state.profile);
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
        setAnchor(event.currentTarget);
      };
    const closeMenu = (event) => {
      event.stopPropagation();
      setAnchor(null);
    };

    useEffect(() => {
        if (user?.result?._id) {
            dispatch(getConnections(user.result._id));
        }
    }, [user?.result?._id, dispatch]);

    const getOtherParticipantDetails = (connection) => {
        return connection.participantDetails.find(participant => participant._id !== user.result._id);
    };

    const handleDeleteChat = (otherParticipantId) => {
        dispatch(deleteConnection(userId, otherParticipantId, history));
        setAnchor(null);
        if (user?.result?._id) {
            dispatch(getConnections(user.result._id));
        }
      };

    if (!user) {
        return (
          <Grid container justifyContent="center">
            <Typography variant="h6" sx={{marginTop: '40px'}}>Sign in to chat with your connections.</Typography>
          </Grid>
        );
      };

    return (
        <Grid sx={{ padding: isMobile ? '20px 14px 20px 14px' : '20px' }}>
            <Typography component="h1" variant="h6" marginTop="0px" marginBottom="10px" fontWeight='bold'>Connections</Typography>
            {isLoading && (<CircularProgress />)}
            {(!isLoading && connections.length == 0) && (
            <Typography component="h1" marginTop="20px" marginBottom="10px">Your conversations with your connections will appear here.</Typography>
            )}
            {(!isLoading && connections.length > 0) && (
            <List>
                {connections.map((connection) => {
                    const otherParticipant = getOtherParticipantDetails(connection);
                    if (!otherParticipant) return null; // Skip rendering if other participant not found

                    const lastMessageContent = connection.lastMessage?.content || "Start the conversation";
                    const lastMessageTime = connection.lastMessage?.timestamp || "";
                    const conciseTimeDiff = lastMessageTime ? getConciseTimeDifference(lastMessageTime) : "";
                    const mobileThreshold = 20;
                    const desktopThreshold = 60;
                    let truncatedMessageContent = isMobile 
                        ? lastMessageContent.substring(0, mobileThreshold) 
                        : lastMessageContent.substring(0, desktopThreshold);
                    if (lastMessageContent.length > (isMobile ? mobileThreshold : desktopThreshold)) {
                        truncatedMessageContent += "...";
                    }

                    return (
                        <ListItem 
                            sx={{ borderRadius: '16px', border: '1px solid #eeeeee', boxShadow: '0px 0px 4px rgba(0,0,0,0.1)', marginBottom: '20px' }}
                            key={connection._id}
                            button
                            onClick={() => history.push(`/profile/discussion/${otherParticipant._id}`)}
                        >
                            <ListItemAvatar>
                                <Avatar src={otherParticipant.profilePicture} alt={otherParticipant.name} variant="rounded"/>
                            </ListItemAvatar>
                            <ListItemText 
                                primary={
                                    <Typography
                                        sx={{ fontSize: '16px', fontWeight: 'bold' }}
                                        component="span"
                                        color="text.primary"
                                    >
                                        {otherParticipant.name}
                                    </Typography>
                                }
                                secondary={ lastMessageTime ?
                                    <React.Fragment>
                                        {truncatedMessageContent}&nbsp;·&nbsp;{conciseTimeDiff}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {truncatedMessageContent}
                                    </React.Fragment>
                                }
                            />
                            <Button sx={{color: 'grey', minWidth: '40px', maxWidth: '40px', '&:hover': {backgroundColor: '#cccccc'}}}
                            size="small" onClick={(event) => {
                                openMenu(event);
                                event.stopPropagation();
                            }} //onClick={openMenu}
                            >
                                <MoreHorizIcon fontSize="medium" />
                            </Button>
                            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={(event) => {closeMenu(event)}} keepMounted >
                                <div>
                                <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation(); // Stop event propagation
                                    handleDeleteChat(otherParticipant._id, event);
                                }}
                                sx={{padding: '10px', margin: '-5px 3px -5px 3px'}}>
                                    <DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete connection
                                </MenuItem>
                                </div>
                            </Menu>
                        </ListItem>
                    )
                })}
            </List>
            )}
        </Grid>
    );
};

export default Connections;
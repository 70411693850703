import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

const PREFIX = 'Post';

const classes = {
    border: `${PREFIX}-border`,
    fullHeightCard: `${PREFIX}-fullHeightCard`,
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    mediaBook: `${PREFIX}-mediaBook`,
    overlay: `${PREFIX}-overlay`,
    overlay2: `${PREFIX}-overlay2`,
    grid: `${PREFIX}-grid`,
    title: `${PREFIX}-title`,
    details: `${PREFIX}-details`,
    content: `${PREFIX}-content`,
    moreOptions: `${PREFIX}-moreOptions`,
    profileLink: `${PREFIX}-profileLink`,
    categoryLink: `${PREFIX}-categoryLink`,
    body: `${PREFIX}-body`,
    tags: `${PREFIX}-tags`,
    cardActions: `${PREFIX}-cardActions`,
    cardAction: `${PREFIX}-cardAction`,
    cardButton: `${PREFIX}-cardButton`
};

const StyledCard = styled(Card)((
  {
      theme
  }
) => ({
  [`& .${classes.border}`]: {
    border: 'solid',
  },
  [`& .${classes.fullHeightCard}`]: {
    height: '100%',
  },
  [`&.${classes.card}`]: {
    //backgroundColor: '#121212', //161616
    //border: '0.1px solid #efefef',
    padding: '20px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '16px',
    height: '100%',
    position: 'relative',
    border: '1px solid #eeeeee',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.1)',
    [theme.breakpoints.up('md')]: {
      marginLeft: '12px',
      marginRight: '12px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  [`& .${classes.media}`]: {
    height: '350px', // Fixed height
    width: '100%', // Fixed width
    objectFit: 'cover', // Ensures the image covers the box, cropping if necessary
    objectPosition: 'center',
    borderRadius: 8,
    marginTop: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backgroundBlendMode: 'darken',
    [theme.breakpoints.down('md')]: {
      height: '240px',
      //height: '96%',
      //width: '96%',
    },
  },
  [`& .${classes.mediaBook}`]: {
    height: '140px',
    //width: '100%',
    borderRadius: 8,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backgroundBlendMode: 'darken',
    [theme.breakpoints.down('md')]: {
      height: '190px',
      //width: '100%',
    },
  },
  [`& .${classes.overlay}`]: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'black',
  },
  [`& .${classes.overlay2}`]: {
    position: 'absolute',
    top: '40px',
    right: '20px',
    color: 'black',
  },
  [`& .${classes.grid}`]: {
    display: 'flex',
  },
  [`& .${classes.title}`]: {
    fontSize: '18px',
    padding: '0px 0px 0px 0px',
  },
  [`& .${classes.details}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    //margin: '20px',
    padding: '5px 0px 0px 0px',
  },
  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
    //margin: '20px',
    padding: '0px 0px 0px 0px',
    [theme.breakpoints.down('md')]: {
      //flexDirection: 'column',
      padding: '0px 0px 0px 0px',
    },
  },
  [`& .${classes.moreOptions}`]: {
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
    "&:hover": {
      boxShadow: "#000000",
      //background: "#F0EFEF"
    },
    minWidth:'35px',
    maxWidth:'35px',
  },

  [`& .${classes.profileLink}`]: {
    borderRadius: 15,
    padding: '5px 10px 5px 10px',
    background:'rgba(173, 216, 230, 0.3)', // 292929
    "&:hover": {
      //boxShadow: "#000000",
      background: "rgba(173, 216, 230, 0.6)" // 333333
    },
  },

  [`& .${classes.categoryLink}`]: {
    borderRadius: 15,
    padding: '5px 10px 5px 10px',
    background:'rgba(35, 35, 35, 0.05)', // 292929 rgba(204, 153, 255, 0.20)
    "&:hover": {
      //boxShadow: "#000000",
      background: "rgba(35, 35, 35, 0.1)" // 333333 rgba(204, 153, 255, 0.4)
    },
  },
  
  [`& .${classes.body}`]: {
    //display: 'flex',
    //justifyContent: 'space-between',
    textAlign: 'justify',
    fontSize: '16px',
    padding: '8px 0px 8px 0px',
  },
  [`& .${classes.tags}`]: {
    //display: 'flex',
    //justifyContent: 'space-between',
    fontSize: '14px',
    padding: '0px 8px 5px 8px',
  },
  [`& .${classes.cardActions}`]: {
    padding: '0px 0px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    //alignItems:'center',
    [theme.breakpoints.down('md')]: {
      //marginLeft: '4px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px 20% 0px 20%',
    },
  },
  [`& .${classes.cardAction}`]: {
    display: 'block',
    textAlign: 'initial',
  },
  [`& .${classes.cardButton}`]: {
    borderRadius: 15,
    textTransform: 'none',
    fontSize: '15px',
    fontWeight: 'light',
    //marginRight: '15px',
    //padding: '0px',
    color:'#8cb8db', // lightGrey #424242
    "&:hover": {
      //boxShadow: "#000000",
      //background: "#F0EFEF"
    },
  },
}));

export default StyledCard;
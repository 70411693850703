import axios from 'axios'; 

const API = axios.create({ baseURL: 'https://connectioncohu-backend-bf534c03be3d.herokuapp.com' }); // or http://localhost:5001

// in order to make the API works, function that happens for every request
// because we have to send our token back to our backend so that the backend middleware can verify that we are actually logged-in
// - Interceptors are code blocks that you can use to preprocess or post-process HTTP calls, helping with global error handling, authentication, logging, and more
// - Axios interceptors are functions that Axios calls for every request. You can use interceptors to transform the request before Axios sends it, or transform the response before Axios returns the response to your code.
API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`; // bearer token (predominant type of access token used with OAuth 2.0. A Bearer Token is an opaque string, not intended to have any meaning to clients using it)
    }

    return req; // return the actual request so that we can make all the future requests (listed below)
});

// sign in and sign up
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const signinGoogleUser = (result) => API.post('/user/signinGoogleUser', result);

// get one specific post (id) from the database
export const fetchPost = (id) => API.get(`/posts/${id}`);

// get the posts from the database
export const fetchPosts = (page, metricFilter, formatFilter, userFollowingApi) => API.get(`/posts?page=${page}&metricFilter=${metricFilter}&formatFilter=${formatFilter}&userFollowingApi=${userFollowingApi}`);

// search- get the posts by search from the database
export const fetchPostsBySearch = (searchQuery, page, metricFilter, formatFilter) => API.get(`/posts/search?searchQuery=${searchQuery || 'none'}&page=${page}&metricFilter=${metricFilter}&formatFilter=${formatFilter}`); //search query starts with "?" and then you specify a variable

// get the posts by category from the database
export const fetchPostsByCategory = (category, page, metricFilter, formatFilter) => API.get(`/posts/category?category=${category}&page=${page}&metricFilter=${metricFilter}&formatFilter=${formatFilter}`);

// get user list (posts saved)
export const fetchPostsByUserList = (userId) => API.get(`/posts/saved?user=${userId}`);

// get one specific profile (id) from the database
export const fetchTopContributors = () => API.get(`/posts/topcontributors`);

// get one specific profile (id) from the database
export const fetchProfile = (id) => API.get(`/profile/${id}`);

// edit one specific profile (id) from the database
export const editProfile = (id, updatedProfile) => API.patch(`/profile/editprofile?user=${id}`, updatedProfile);

// get profiles to match from the database
export const getSimilarProfiles = (id) => API.get(`/profile/connect?user=${id}`);

// get profiles to match from the database
export const getConnectionRequests = (id) => API.get(`/profile/connectRequests?user=${id}`);

// get profiles to match from the database
export const getAISearchProfiles = (id, request) => API.get(`/profile/aiconnect?user=${id}&request=${request}`);

// get profiles to match from the database
export const getConnections = (id) => API.get(`/profile/getConnections?user=${id}`);

// edit one specific profile (id) from the database
export const connectProfiles = (id, profileId, action) => API.patch(`/profile/connectProfiles`, { id, profileId, action });

// edit one specific profile (id) from the database
export const deleteConnection = (id, profileId) => API.patch(`/profile/deleteConnection`, { id, profileId });

// get conversation
export const getConversation = (profileId1, profileId2) => API.get(`/profile/getConversation`, {params: { profileId1, profileId2 }});

// send message to conversation
export const sendChatMessage = (newMessage) => API.patch(`/profile/sendChatMessage`, { newMessage });

// store a (whole) new post in the database
export const createPost = ( newPost ) => API.post('/posts', newPost);

//update a post in the database
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);

// create ai summary
export const createAISummary = ( id ) => API.patch(`/posts/${id}/createAISummary`, id);

// delete a post in the database
export const deletePost = (id) => API.delete(`/posts/${id}`);

//update a post in the database
export const addTags = (id, tags) => API.patch(`/posts/${id}/addTags`, tags);

// like a post in the database
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

// downvote a post in the database
export const downvotePost = (id) => API.patch(`/posts/${id}/downvotePost`);

// comment a post
export const comment = (comment, id) => API.post(`/posts/${id}/commentPost`, { comment });

// delete a post in the database
export const deleteComment = (id, commentId) => API.patch(`/posts/${id}/deleteComment`, { commentId });

// like a comment in the database
export const likeComment = (id, commentId) => API.patch(`/posts/${id}/likeComment`, { commentId });

// downvote a comment in the database
export const downvoteComment = (id, commentId) => API.patch(`/posts/${id}/downvoteComment`, { commentId });

// add a post to user list in the database
export const addPostToUserList = (id) => API.patch(`/posts/${id}/addPostToUserList`);
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, Box, Typography, Button, Divider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

import landingImage from '../../images/1.png';
import landingImage2 from '../../images/2.png';
import landingImage3 from '../../images/3.png';
import landingImage4 from '../../images/4.png';
import connectScreenshot from '../../images/connectScreenshot.png';
import feedScreenshot from '../../images/feedScreenshot.png';
import aisearchScreenshot from '../../images/AIConnectScreenshot.png';
import connectScreenshotDesktop from '../../images/connectScreenshotDesktop.png';
import feedScreenshotDesktop from '../../images/feedScreenshotDesktop.png';

const useStyles = makeStyles((theme) => ({
  topFrame: {
    position: 'relative',
    overflow: 'hidden',
  },
  backgroundImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.07,
    zIndex: -1,
  },
  content: {
    display: 'flex',
    //justifyContent: 'space-between',
    flexDirection: 'column',
    //justifyContent: 'center',
    //alignItems: 'center',
    padding: '20px', //theme.spacing(3),
    margin: '20px 0px 20px 0px',
    zIndex: 1,
  },
  leftSection: {
    flex: 1,
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '14px 0px 0px',
  },
  title: {
    marginTop:'5px',
    textAlign: 'center',
    lineHeight: '1.35',
  },
  description: {
    textAlign: 'center',
    color: 'lightGrey',
  },
  noteLogin: {
    fontSize: 14,
    marginLeft:'8px',
    textAlign: 'center',
    color: '#8c8c8c',
  },
  loginButton: {
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      boxShadow: "#000000",
      //background: "#39796d"
    },
  },
  gradientText: {
    background: 'linear-gradient(90deg, #6aa3d9 30%, #a46ad9 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
}));

const LandingIntro = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Box className={classes.topFrame}>
      {/*<img
        className={classes.backgroundImg}
        src={bgImage}
        alt="Background"
      />*/}
      <Box className={classes.content}>
        {/*<Box className={classes.leftSection}>*/}
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} className={classes.title}>
          Make new connections, be part of the community
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{marginTop: '8px', textAlign: 'center'}}>
          Want to connect with like-minded individuals and build a sense of belonging?
          </Typography>
          {/*<Typography variant="h6" sx={{ fontWeight: 'bold' }} className={classes.title}>
            in the format humans love the most: posts.
          </Typography>*/}
          
          {/*<Typography variant="body1" className={classes.description} margin={isMobile ? '10px 0px 5px 0px' : '5px 0px 5px 0px'}>
          Our ambition is to become the social platform of reference for sharing and accessing the best knowledge.
          </Typography>*/}
          
        {/*</Box>*/}
        <Box className={classes.rightSection}>
          <Button variant="contained" size="medium" className={classes.loginButton}
          component={Link} to="/auth" disableElevation
          sx={{ color: 'white', borderColor: 'black', fontSize: '16px', fontWeight: 'bold'}}
          >
            Sign in
          </Button>
        </Box>
      </Box>
      
      <Box sx={{margin: '50px 0px 50px 0px'}}>
        {/*<Divider/>*/}
      </Box>

      <Box sx={{padding: '13px'}}>
        {/*<Box className={classes.leftSection}>*/}
          <Typography className={classes.gradientText} variant="h6" sx={{ fontWeight: 'bold' }}>
          Make meaningful connections based on who you really are.
          </Typography>
          <Grid display="flex" flexDirection="column" alignItems="center">
            <img
              //className={classes.backgroundImg}
              src={connectScreenshotDesktop}
              width={isMobile ? "100%" : "80%"}
              alt="Background"
            />
          </Grid>
        </Box>

        <Box sx={{margin: '30px 0px 30px 0px'}}>
          {/*<Divider/>*/}
        </Box>

        <Box sx={{padding: '13px'}}>
        {/*<Box className={classes.leftSection}>*/}
          <Typography className={classes.gradientText} variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
          Find the ideal match for your specific needs using AI.
          </Typography>
          <Grid display="flex" flexDirection="column" alignItems="center" marginTop="10px">
            <img
              //className={classes.backgroundImg}
              src={aisearchScreenshot}
              width={isMobile ? "70%" : "55%"}
              alt="Background"
            />
          </Grid>
        </Box>

        <Box sx={{margin: '30px 0px 30px 0px'}}>
          {/*<Divider/>*/}
        </Box>

        <Box sx={{padding: '13px'}}>
        {/*<Box className={classes.leftSection}>*/}
          <Typography className={classes.gradientText} variant="h6" sx={{ fontWeight: 'bold' }}>
          See what is happening around you and participate in the community.
          </Typography>
          <Grid display="flex" flexDirection="column" alignItems="center" marginTop="10px">
            <img
              //className={classes.backgroundImg}
              src={feedScreenshotDesktop}
              width={isMobile ? "100%" : "80%"}
              alt="Background"
            />
          </Grid>
        </Box>
        
        <Box sx={{margin: '50px 0px 50px 0px'}}>
          {/*<Divider/>*/}
        </Box>

        <Box sx={{padding: '13px'}}>
        {/*<Box className={classes.leftSection}>*/}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          The future of human connection
          </Typography>

          <Grid display="flex" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "center" : "center"} sx={{marginTop: isMobile ? '30px' : '60px'}}>
            <img
              //className={classes.backgroundImg}
              src={landingImage}
              width={isMobile ? "80%" : "200px"}
              alt="Background"
            />
            <Grid sx={{marginLeft: isMobile ? '' : '30px'}}>
              <Typography variant="body1" sx={{fontWeight: 'bold', textAlign: 'justify', fontSize: '18px', marginTop: isMobile ? '10px' : ''}}>
              Our philosophy
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{marginTop: '8px', textAlign: 'justify'}}>
              Meaningful social connections and belonging to a community are crucial for our happiness, well-being and personal development.
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{marginTop: '2px', textAlign: 'justify'}}>
              A way to support this is essential, particularly in a world where the use of technology is growing rapidly, and where people are relocating more and more, evolving fast, and sometimes feeling lonely.
              </Typography>
            </Grid>
          </Grid>

          <Grid display="flex" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "center" : "center"} sx={{marginTop: isMobile ? '30px' : '60px'}}>
            {isMobile && (
            <img
              //className={classes.backgroundImg}
              src={landingImage2}
              width={isMobile ? "80%" : "200px"}
              alt="Background"
            />
            )}
            <Grid sx={{marginRight: isMobile ? '' : '30px'}}>
              <Typography variant="body1" sx={{fontWeight: 'bold', textAlign: 'justify', fontSize: '18px', marginTop: isMobile ? '10px' : ''}}>
              Our approach
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{marginTop: '8px', textAlign: 'justify'}}>
              Our goal is to connect you with incredible people, with whom you can hang out and grow, based on who you really are, your shared interests and personalities.
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{marginTop: '2px', textAlign: 'justify'}}>
              We've created an interface where you can fully express yourself, and developed an algorithm that understands you and puts you in touch with the most relevant people and things happening around you.
              </Typography>
            </Grid>
            {!isMobile && (
            <img
              //className={classes.backgroundImg}
              src={landingImage2}
              width={isMobile ? "" : "200px"}
              alt="Background"
            />
            )}
          </Grid>
          {/*<Typography variant="body1" color="textSecondary" sx={{marginTop: '8px', textAlign: 'justify'}}>
          We believe that in a world where the use of technology is growing and where people are relocating more and more, and sometimes feel lonely, a way of creating local and meaningful connections is essential.
          </Typography>*/}
        </Box>

        <Box sx={{margin: '50px 0px 80px 0px'}}>
          {/*<Divider/>*/}
        </Box>

        <Grid display="flex" flexDirection="row" justifyContent="center">
          <Typography component={Link} to="/terms" color="textSecondary" marginRight="10px">Terms of Use</Typography>
          <Typography component={Link} to="/privacy" color="textSecondary" marginLeft="10px">Privacy Policy</Typography>
        </Grid>
    </Box>
  );
};

export default LandingIntro;
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #36d2f5 30%, #40f1b0 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const Terms = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"
    sx={{ marginTop: '10px' }}>
        
        <Grid container display="flex" flexDirection="column" justifyContent="center" padding='0 5% 0 5%'>
            {/*<Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'32px', fontWeight: 'bold'} : { fontSize:'48px', fontWeight: 'bold' }} >Building the future of research.</Typography>*/}
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                Terms of Use
            </Typography>

            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Last updated: February 1, 2024
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            1.	Acceptance of Terms of Use Agreement.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            By creating a Cohuma account, whether through a mobile device, mobile application or computer (collectively, the “Service”) you agree to be bound by (i) these Terms of Use, (ii) our Privacy Policy, which is incorporated by reference into this Agreement, and (iii) any terms disclosed and agreed to by you if you purchase additional features, products or services we offer on the Service (collectively, this "Agreement"). If you do not accept and agree to be bound by all of the terms of this Agreement, please do not use the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            We may make changes to this Agreement and to the Service from time to time. We may do this for a variety of reasons including to reflect changes in or requirements of the law, new features, or changes in business practices. If you continue to use the Service after the changes become effective, then you agree to the revised Agreement.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            2. Eligibility.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You are not authorized to create an account or access or use the Service or systems it resides on unless all of the following are true:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	you are at least 18 years of age.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	you can form a binding contract with Cohuma,
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	you are not a person who is barred from using the Service under the laws of the United States or any other applicable jurisdiction (for example, you do not appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other similar prohibition),
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	you will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations, and
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	you have never been convicted of a felony or indictable offense (or crime of similar severity), a sex crime, or any crime involving violence, and that you are not required to register as a sex offender with any state, federal or local sex offender registry.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            3. Your Account.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            In order to use Cohuma, you may sign in using a number of ways. For more information regarding the information we collect from you and how we use it, please consult our Privacy Policy.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You are responsible for maintaining the confidentiality of your login credentials you use to sign up for Cohuma, and you are solely responsible for all activities that occur under those credentials. If you think someone has gained access to your account, please immediately contact us.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            4. Modifying the Service and Termination.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Cohuma is always striving to improve the Service and bring you additional functionality that you will find engaging and useful. This means we may add new product features or enhancements from time to time as well as remove some features, and if these actions do not materially affect your rights or obligations, we may not provide you with notice before taking them. We may even suspend the Service entirely, in which event we will notify you in advance unless extenuating circumstances, such as safety or security concerns, prevent us from doing so.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            The easiest way to terminate your account is to follow the instructions under "Settings" in the Service. Of course, you retain other termination options. However, if you use a third party payment account such as Apple’s App Store or iTunes Store, as applicable (“App Store”) or the Google Play Store, you will need to manage in app purchases through such an account to avoid additional billing. Cohuma may terminate your account at any time without notice if it believes that you have violated this Agreement. Upon such termination, you will not be entitled to any refund for purchases.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            For residents of the Republic of Korea, except in the case where we reasonably consider that (i) giving notice is legally prohibited (for instance, when providing notice would either violate applicable laws, regulations, or orders from regulatory authorities or compromise an ongoing investigation conducted by a regulatory authority) or (ii) any notice may cause harm to you, third parties, Cohuma, and/or our affiliates (for instance, when providing notice harms the security of the Service), we will without delay notify you of the reason for taking the relevant step.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            After your account is terminated, this Agreement will terminate, except that the following provisions will still apply to you and Cohuma: Section 4, Section 5, and Sections 12 through 16.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            5. Safety; Your Interactions with Other Members.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Though Cohuma strives to encourage a respectful member experience through features like the double opt-in that allows members to communicate only after they have both indicated interest in one another, Cohuma is not responsible for the conduct of any member on or off of the Service. You agree to use caution in all interactions with other members, particularly if you decide to communicate off the Service or meet in person. You agree that you will not provide your financial information (for example, your credit card or bank account information), or wire or otherwise send money to other members.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT COHUMA DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS. COHUMA MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OR COMPATIBILITY OF MEMBERS.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            6. Rights Cohuma Grants You.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Cohuma grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and non-sublicensable license to access and use the Service. This license is for the sole purpose of letting you use and enjoy the Service’s benefits as intended by Cohuma and permitted by this Agreement. This license and any authorization to access the Service are automatically revoked in the event that you do any of the following:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use the Service or any content contained in the Service for any commercial purposes without our written consent.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, images, trademarks, trade names, service marks, or other intellectual property, content or proprietary information accessible through the Service without Cohuma’s prior written consent.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	express or imply that any statements you make are endorsed by Cohuma.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use any robot, bot, spider, crawler, scraper, site search/retrieval application, proxy or other manual or automatic device, method or process to access, retrieve, index, "data mine", or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use the Service in any way that could interfere with, disrupt or negatively affect the Service or the servers or networks connected to the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	upload viruses or other malicious code or otherwise compromise the security of the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	"frame" or "mirror" any part of the Service without Cohuma’s prior written authorization.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use meta tags or code or other devices containing any reference to Cohuma or the Service (or any trademark, trade name, service mark, logo or slogan of Cohuma) to direct any person to any other website for any purpose.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service, or cause others to do so.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use or develop any third-party applications that interact with the Service or other members' Content or information without our written consent.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use, access, or publish the Cohuma application programming interface without our written consent.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	probe, scan or test the vulnerability of our Service or any system or network.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	encourage or promote any activity that violates this Agreement.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Cohuma may investigate and take any available legal action in response to illegal or unauthorized uses of the Service, including termination of your account.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device's settings.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            7. Rights you Grant Cohuma.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            By creating an account, you grant to Cohuma a worldwide, transferable, sub-licensable, royalty-free, right and license to host, store, use, copy, display, reproduce, adapt, edit, publish, modify and distribute information you authorize us to access from third parties such as Facebook, as well as any information you post, upload, display or otherwise make available (collectively, "post") on the Service or transmit to other members (collectively, "Content"). Cohuma's license to your Content shall be non-exclusive, except that Cohuma's license shall be exclusive with respect to derivative works created through use of the Service. For example, Cohuma would have an exclusive license to screenshots of the Service that include your Content. In addition, so that Cohuma can prevent the use of your Content outside of the Service, you authorize Cohuma to act on your behalf with respect to infringing uses of your Content taken from the Service by other members or third parties. This expressly includes the authority, but not the obligation, to send notices pursuant to 17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf if your Content is taken and used by third parties outside of the Service. Our license to your Content is subject to your rights under applicable law (for example laws regarding personal data protection to the extent any Content contains personal information as defined by those laws) and is for the limited purpose of operating, developing, providing, and improving the Service and researching and developing new ones. You agree that any Content you place or that you authorize us to place on the Service may be viewed by other members and may be viewed by any person visiting or participating in the Service (such as individuals who may receive shared Content from other Cohuma members).
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You agree that all information that you submit upon creation of your account, including information submitted from your Facebook account, is accurate and truthful and you have the right to post the Content on the Service and grant the license to Cohuma above.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You understand and agree that we may monitor or review any Content you post as part of a Service. We may delete any Content, in whole or in part, that in our sole judgment violates this Agreement or may harm the reputation of the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            When communicating with our customer care representatives, you agree to be respectful and kind. If we feel that your behavior towards any of our customer care representatives or other employees is at any time threatening, harassing, or offensive, we reserve the right to immediately terminate your account.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            In consideration for Cohuma allowing you to use the Service, you agree that we, our affiliates, and our third-party partners may place advertising on the Service. By submitting suggestions or feedback to Cohuma regarding our Service, you agree that Cohuma may use and share such feedback for any purpose without compensating you.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Please be informed that Cohuma may access, store and disclose your account information and Content if required to do so by law, by performing its agreement with you, or in a good faith belief that such access, storage or disclosure satisfies a legitimate interest, including to: (i) comply with legal process; (ii) enforce the Agreement; (iii) respond to claims that any Content violates the rights of third parties; (iv) respond to your requests for customer service; or (v) protect the rights, property or personal safety of the Company or any other person.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            8. Community Rules.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            By using the Service, you agree that you will not:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use the Service for any purpose that is illegal or prohibited by this Agreement.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use the Service for any harmful or nefarious purpose
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use the Service in order to damage Cohuma
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	violate our Community Guidelines, as updated from time to time.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	spam, solicit money from or defraud any members.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	impersonate any person or entity or post any images of another person without his or her permission.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	bully, "stalk", intimidate, assault, harass, mistreat or defame any person.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	post any Content that violates or infringes anyone's rights, including rights of publicity, privacy, copyright, trademark or other intellectual property or contract right.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	post any Content that is hate speech, threatening, sexually explicit or pornographic.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	post any Content that incites violence; or contains nudity or graphic or gratuitous violence.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	post any Content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other members or disseminate another person's personal information without his or her permission.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	use another member's account, share an account with another member, or maintain more than one account.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	create another account if we have already terminated your account, unless you have our permission.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Cohuma reserves the right to investigate and/or terminate your account without a refund of any purchases if you have violated this Agreement, misused the Service or behaved in a way that Cohuma regards as inappropriate or unlawful, including actions or communications that occur on or off the Service. 
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            9. Other Members' Content.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Although Cohuma reserves the right to review and remove Content that violates this Agreement, such Content is the sole responsibility of the member who posts it, and Cohuma cannot guarantee that all Content will comply with this Agreement. If you see Content on the Service that violates this Agreement, please report it by contacting us.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            10. Notice and Procedure for Making Claims of Copyright Infringement.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            If you believe that your work has been copied and posted on the Service in a way that constitutes copyright infringement, please contact us.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            If you contact us regarding alleged copyright infringement, please be sure to include the following information:
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	a description of the copyrighted work that you claim has been infringed;
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	a description of where the material that you claim is infringing is located on the Service (and such description must be reasonably sufficient to enable us to find the alleged infringing material);
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	your contact information, including address, telephone number and email address and the copyright owner’s identity;
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            •	a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            Cohuma will terminate the accounts of repeat infringers.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            12. Disclaimers.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            COHUMA PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. COHUMA DOES NOT REPRESENT OR WARRANT THAT (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE, (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE WILL BE ACCURATE.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            COHUMA TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER MEMBER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            13. Third Party Services.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            The Service may contain advertisements and promotions offered by third parties and links to other web sites or resources. Cohuma is not responsible for the availability (or lack of availability) of such external websites or resources. If you choose to interact with the third parties made available through our Service, such party's terms will govern their relationship with you. Cohuma is not responsible or liable for such third parties' terms or actions.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            14. Limitation of Liability.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COHUMA, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER MEMBERS OR THIRD PARTIES ON, THROUGH, OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT, EVEN IF COHUMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL COHUMA’S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED THE GREATER OF THE AMOUNT PAID, IF ANY, BY YOU TO COHUMA FOR THE SERVICE AND USD100 WHILE YOU HAVE AN ACCOUNT.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            14. Limitation of Liability.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COHUMA, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER MEMBERS OR THIRD PARTIES ON, THROUGH, OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT, EVEN IF COHUMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL COHUMA’S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED THE GREATER OF THE AMOUNT PAID, IF ANY, BY YOU TO COHUMA FOR THE SERVICE AND USD100 WHILE YOU HAVE AN ACCOUNT.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            15. Indemnity by You.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            You agree, to the extent permitted under applicable law, to indemnify, defend and hold harmless Cohuma, our affiliates, and their and our respective officers, directors, agents, and employees from and against any and all complaints, demands, claims, damages, losses, costs, liabilities and expenses, including attorney’s fees due to, arising out of, or relating in any way to your access to or use of the Service, your Content, or your breach of this Agreement.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            16. Entire Agreement; Other.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            This Agreement, which includes the Privacy Policy, and any terms disclosed and agreed to by you if you purchase additional features, products or services we offer on the Service, contains the entire agreement between you and Cohuma regarding the use of the Service. If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect. The failure of the Company to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. You agree that your Cohuma account is non-transferable and all of your rights to your account and its Content terminate upon your death. No agency, partnership, joint venture, fiduciary or other special relationship or employment is created as a result of this Agreement and you may not make any representations on behalf of or bind Cohuma in any manner.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Contact us
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
            If you have any questions about this Agreement, please contact us.
            </Typography>
            
        </Grid>

        <Grid display="flex" flexDirection="row" justifyContent="center" marginTop="50px">
          <Typography component={Link} to="/terms" color="textSecondary" marginRight="10px">Terms of Use</Typography>
          <Typography component={Link} to="/privacy" color="textSecondary" marginLeft="10px">Privacy Policy</Typography>
        </Grid>

        {/*<Grid>
        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center" spacing={isMobile? 1 : 5}
        sx={{ margin: '40px 0px 20px 0px' }}>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/about" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    About
                </Link>
            </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/contact" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Contact
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/terms" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
              <Link 
                to="/privacy" 
                style={{ color: 'Grey', textDecoration: 'none' }}
              >
                Privacy
              </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey'}}>
                <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: 'Grey', "textDecoration": "none"}}>Twitter</a>
              </Typography>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '30px 10px 30px 10px' : '30px 60px 30px 60px'}>        
        </Grid>

      </Grid>*/}

    </Grid>
  );
};

export default Terms;